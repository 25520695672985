.breadcrumbs{
	margin-bottom: 4.5rem;
	font-weight: bold;
	text-transform: uppercase;
	ol,
	ul{
		display: inline-block;
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	ol{
		> li{
			@include media-breakpoint-up(lg){
				display: inline-block;
			}
		}
	}
	ul{
		vertical-align: top;
	}
	.breadcrumb{
		background-color: transparent;
	}
	.breadcrumb-item{
		+ .breadcrumb-item{
			padding-left: 0;
			&::before{
				color: $gray-500;
				content: '\2014';
				font-family: Montserrat, Helvetica, Arial, sans-serif;
				font-weight: 700;
			}
			@include media-breakpoint-up(lg){
				&::before{
					padding-left: .5rem;
					content: '\f715';
					font-family: 'Font Awesome 5 Free';
					transform: scaleX(-1);
				}
			}
		}
	}
}