.fas{
	&.banden::before{
		content: "\f192";
	}
	&.budget::before{
		content: "\f4d3";
	}
	&.gebruik::before{
		content: "\f0a6";
	}
	&.keuze::before{
		content: "\f128";
	}
	&.sneeuwkettingen::before{
		content: "\f0c1";
	}
	&.veiligheid::before{
		content: "\f3ed";
	}
	&.wetgeving::before{
		content: "\f02d";
	}
}