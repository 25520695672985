.badge{
	border: 0 none transparent;
	background-color: $gray-900;
	color: $white;
	font-size: 100%;
	font-weight: 800 !important;
	text-transform: uppercase !important;
	transition: none;
	&:hover{
		color: $white;
	}
	&.prefix{
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	&.infix{
		border-radius: 0;
	}
	&.suffix{
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	&.primary,
	&.personenwagen,
	&.dak{
		@include themify{
			background-color: map-get($accent-primary, 'background-color') !important;
			color: map-get($accent-primary, 'color') !important;
		}
	}
	&.secondary,
	&.suv-of-crossover,
	&.trekhaak{
		@include themify{
			background-color: map-get($accent-secondary, 'background-color') !important;
			color: map-get($accent-secondary, 'color') !important;
		}
	}
	&.tertiary,
	&.monovolume,
	&.kofferbak{
		@include themify{
			background-color: map-get($accent-tertiary, 'background-color') !important;
			color: map-get($accent-tertiary, 'color') !important;
		}
	}
	&.accent-primary{
		@include themify{
			background-color: map-get($accent-primary, 'background-color') !important;
			color: map-get($accent-primary, 'color') !important;
		}
	}
	&.accent-secondary{
		@include themify{
			background-color: map-get($accent-secondary, 'background-color') !important;
			color: map-get($accent-secondary, 'color') !important;
		}
	}
	&.accent-tertiary{
		@include themify{
			background-color: map-get($accent-tertiary, 'background-color') !important;
			color: map-get($accent-tertiary, 'color') !important;
		}
	}
	/*&.montage{
		background-color: $gray-600;
	}*/
	&.light{
		@include themify{
			background-color: map-get($light, 'background-color') !important;
			color: map-get($light, 'color') !important;
		}
	}
	&.dark{
		@include themify{
			background-color: map-get($dark, 'background-color') !important;
			color: map-get($dark, 'color') !important;
		}
	}
	&.daytime{
		@include themify{
			background-color: map-get($daytime, 'background-color') !important;
			color: map-get($daytime, 'color') !important;
		}
	}
	&.nighttime{
		@include themify{
			background-color: map-get($nighttime, 'background-color') !important;
			color: map-get($nighttime, 'color') !important;
		}
	}
	&.true{
		@include themify{
			background-color: map-get($success, 'background-color');
			color: map-get($success, 'color');
		}
	}
	&.false{
		@include themify{
			background-color: map-get($danger, 'background-color');
			color: map-get($danger, 'color');
		}
	}
	&.tag,
	&.rubriek{
		&::before{
			display: inline-block;
			width: 1.25em;
			padding-right: .5rem;
			content: '\00b7';
		}
	}
	&.merk{
		@include themify{
			background-color: map-get($nighttime, 'background-color');
			color: map-get($nighttime, 'color');
		}
	}
	&.title{
		@include themify{
			background-color: map-get($daytime, 'background-color');
			color: map-get($daytime, 'color');
		}
		
	}
}

.merk-title{
	font-size: 0;
	.badge{
		font-size: 1rem;
	}
}

/* Muisovereffect uitgeschakeld */
.badge-collapse{
	span{
		&:first-of-type{
			/*border-top-right-radius: $border-radius;
			border-bottom-right-radius: $border-radius;*/
			border-radius: $border-radius !important;
		}
		&:last-of-type{
			display: none;
		}
	}
	&:hover{
		span{
			&:first-of-type{
				border-top-right-radius: inherit;
				border-bottom-right-radius: inherit;
			}
			&:last-of-type{
				/*display: inline-block;*/
				display: none;
			}
		}
	}
}