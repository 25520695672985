.products{
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.product-group{
	box-shadow: 0 0 1.5rem 0 rgba($gray-500, .5);

	.card-img{
		&:after {
			content:'';
			position:absolute;
			left:0; top:0;
			width:100%; height:100%;
			display:inline-block;
			background: linear-gradient(to bottom, rgba($white, .5) 0%,rgba($white, 0) 100%);
		  }
	}
}

.product,
.product-group{

	box-shadow: 0 0 1.5rem 0 rgba($gray-500, .5);

	// OPKUISEN!!!

	.title{
		max-width: 15rem;
	}
	@include media-breakpoint-up(xs){
		.title{
			max-width: 30rem;
		}
	}
	@include media-breakpoint-up(md){
		.title{
			max-width: 15rem;
		}
	}
	@include media-breakpoint-up(xl){
		.title{
			max-width: 18rem;
		}
	}

	.card-header{
		@include themify{
			background-color: map-get($primary, 'background-color');
			color: map-get($primary, 'color');
		}
		h3{
			margin: inherit;
			background-color: inherit;
			font-family: inherit;
			white-space: nowrap;
			&:after{
				content: none;
			}
		}
		.actions{
			display: none;
			white-space: nowrap;
		}
	}
	.card-img{
		position: relative;
		text-align: center;
		&:hover{
			background-color: rgba($white, 1);
		}
		h3{
			margin: inherit;
			background-color: inherit;
			font-family: inherit;
			white-space: nowrap;
			&:after{
				content: none;
			}
		}
		.properties{
			position: absolute;
			z-index: 10;
			top: .75rem;
			right: .75rem;
			bottom: .75rem;
			left: .75rem;
			pointer-events: none;
			text-align: left;
			& > div:not(:first-of-type){
				//margin-top: .5rem;
			}
			& > div{
				display: none;
				&.productkenmerk{
					display: block;
				}
			}
			span{
				margin-bottom: .5rem;
			}
		}
		img{
			position: relative;
			max-width: 100%;
			object-fit: cover;
			opacity: .90;
		}
	}
	&:hover{
		.card-header{
			.actions{
				display: block;
			}
		}
		.card-img{
			.properties{
				& > div{
					display: block;
				}
			}
		}
	}
	&.wagentype-personenwagen{
		.card-header{
			@include themify{
				background-color: map-get($accent-primary, 'background-color');
				color: map-get($accent-primary, 'color');
			}
		}
		.card-footer{
			@include themify{
				border-bottom-color: map-get($accent-primary, 'background-color');
			}
		}
	}
	&.wagentype-suv-of-crossover{
		.card-header{
			@include themify{
				background-color: map-get($accent-secondary, 'background-color');
				color: map-get($accent-secondary, 'color');
			}
		}
		.card-footer{
			@include themify{
				border-bottom-color: map-get($accent-secondary, 'background-color');
			}
		}
	}
	&.wagentype-monovolume{
		.card-header{
			@include themify{
				background-color: map-get($accent-tertiary, 'background-color');
				color: map-get($accent-tertiary, 'color');
			}
		}
		.card-footer{
			@include themify{
				border-bottom-color: map-get($accent-tertiary, 'background-color');
			}
		}
	}
	&.montage-dak{
		.card-header{
			@include themify{
				background-color: map-get($accent-primary, 'background-color');
				color: map-get($accent-primary, 'color');
			}
		}
		.card-footer{
			@include themify{
				border-bottom-color: map-get($accent-primary, 'background-color');
			}
		}
	}
	&.montage-trekhaak{
		.card-header{
			@include themify{
				background-color: map-get($accent-secondary, 'background-color');
				color: map-get($accent-secondary, 'color');
			}
		}
		.card-footer{
			@include themify{
				border-bottom-color: map-get($accent-secondary, 'background-color');
			}
		}
	}
	&.montage-kofferbak{
		.card-header{
			@include themify{
				background-color: map-get($accent-tertiary, 'background-color');
				color: map-get($accent-tertiary, 'color');
			}
		}
		.card-footer{
			@include themify{
				border-bottom-color: map-get($accent-tertiary, 'background-color');
			}
		}
	}
}

.huurtarief{
	.badge:first-of-type{
		@include themify{
			background-color: map-get($nighttime, 'background-color');
			color: map-get($nighttime, 'color');
		}
	}
	.badge:last-of-type{
		@include themify{
			background-color: map-get($daytime, 'background-color');
			color: map-get($daytime, 'color');
			a:link,
			a:visited,
			a:hover,
			a:active{
				color: map-get($daytime, 'color') !important;
			}
		}
	}
}

/*.actions{
	.badge{
		@include themify{
			background-color: map-get($nighttime, 'background-color');
			color: map-get($nighttime, 'color');
		}
	}
}*/

.barcode{
	//background-color: orange;
	font-size: 0;
	text-align: center;
	.digit{
		display: inline-block;
		height: 3rem;
		background-color: $gray-900;
		&-1{
			width: 1px;
		}
		&-2{
			width: 2px;
		}
		&-3{
			width: 3px;
		}
		&-4{
			width: 4px;
		}
		&-5{
			width: 5px;
		}
	}
	.space{
		&-1{
			margin-right: 1px;
		}
		&-2{
			margin-right: 2px;
		}
		&-3{
			margin-right: 3px;
		}
		&-4{
			margin-right: 4px;
		}
		&-5{
			margin-right: 5px;
		}
	}
}

a.variant{
	.badge{
		width: 1.5rem;
		height: 1.5rem;

		&.antraciet{
			background-color: $gray-800 !important;
		}
		&.carbon{
			background-color: rgba($gray-900, 1) !important;
			background-image: linear-gradient(45deg, rgba($black, 1) 25%, transparent 25%, transparent 75%, rgba($black, 1) 75%, rgba($black, 1)), linear-gradient(45deg, rgba($black, 1) 25%, transparent 25%, transparent 75%, rgba($black, 1) 75%, rgba($black, 1)), linear-gradient(to bottom, rgba($black, 1), rgba($gray-800, .5)) !important;
			background-size: 10px 10px, 10px 10px, 10px 5px !important;
			background-position: 0px 0px, 5px 5px, 0px 0px !important;
		}
		&.matzwart{
			background-color: $gray-900 !important;
		}
		&.titanium{
			background-color: $gray-700 !important;
		}
		&.titaniumglanzend{
			background-color: $gray-700 !important;
			background: linear-gradient(0deg, rgba($gray-700, 1) 50%, rgba($white, .15) 50%, rgba($white, .5) 100%);
		}
		&.wit{
			background-color: $white !important;
		}
		&.zilvergrijs{
			background-color: $gray-500 !important;
		}
		&.zwart{
			background-color: $gray-900 !important;
		}
		&.zwartglanzend{
			background-color: $gray-900 !important;
			background: linear-gradient(0deg, rgba($gray-900, 1) 50%, rgba($white, .15) 50%, rgba($white, .5) 100%);
		}

		span:first-of-type{
			opacity: 0;
		}
	}
}