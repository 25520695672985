.dropdown-menu{
	padding-top: 0;
	padding-bottom: 0;
	overflow: hidden;
	ul{
		padding-left: 0;
		border-radius: $border-radius;
		list-style-type: none;
		li{
			&:last-child{
				a{
					border-bottom-left-radius: $border-radius;
					border-bottom-right-radius: $border-radius;
				}
			}
		}
	}
	.dropdown-item{
		padding-left: 1.25rem;
		padding-right: 1.25rem;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.navbar{
	padding-top: 0;
	padding-bottom: 0;
	background-color: $gray-100;
	> .container-fluid{
		padding: 1rem 0;
	}
	.nav-link{
		padding-top: 0;
		padding-bottom: 0;
	}
	.order-first{
		margin-right: auto !important;
		.brand{
			float: left;
		}
		.navigation{
			float: right;
		}
	}
	.order-last{
		margin-left: auto !important;
		.navigation{
			float: right;
		}
		.storage{
			float: left;
		}
	}
	.brand{
		flex-direction: row-reverse;
		margin-right: .5rem;
		> span{
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			@include themify{
				background-color: map-get($primary, 'background-color');
			}
			a{
				color: $white;
			}
		}
		.dropdown-toggle{
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			&::after{
				content: none;
			}
		}
		.dropdown-menu{
			margin-top: 0;
			border: none;
			background-color: transparent;
			ul{
				padding-left: 0;
				list-style-type: none;
			}
			.dropdown-item{
				padding: inherit;
			}
			li{
				margin-right: 0;
				a{
					&.sneeuwkettingen,
					&.dakkoffer,
					&.fietsdrager{
						.badge:first-of-type{
							background-color: $gray-900;
							color: $white;
						}
					}
					&.sneeuwkettingen{
						.badge:last-of-type{
							color: $white;
							background-color: $brand-sneeuwkettingenhuren;
						}
					}
					&.dakkoffer{
						.badge:last-of-type{
							color: $white;
							background-color: $brand-dakkofferhuren;
						}
					}
					&.fietsdrager{
						.badge:last-of-type{
							color: $white;
							background-color: $brand-fietsdragerhuren;
						}
					}
				}
			}
		}
	}
	.navigation{
		&.primary{
			display: none;
			@include media-breakpoint-up(lg){
				display: flex;
			}
			li{
				margin-right: .5rem;
			}
		}
		&.secondary{
			.dropdown-toggle{
				&::after{
					content: none;
				}
			}
			.dropdown-menu{
				border-color: $gray-600;
				background-color: $gray-900;
				.divider{
					border-top: 1px solid $gray-600;
					margin-top: .25rem;
					padding-top: .25rem;
				}
				.primary{
					@include media-breakpoint-up(lg){
						display: none;
					}
				}
				.secondary{
					
				}
				.tertiary{
					
				}
				.dropdown-item{
					&:hover,
					&:focus{
						background-color: $gray-800;
						color: $gray-100;
					}
					&.active{
						background-color: $gray-100;
						color: $gray-900;
					}
				}
				.dropdown-submenu{
					.dropdown-item{
						padding-right: 1.5rem;
						border-radius: 0;
						&::after{
							color: $gray-800;
							content: '\2014';
							font-family: Montserrat, Helvetica, Arial, sans-serif;
							font-weight: 700;
							padding-left: .5rem;
						}
						&:link{
							/*background-color: $gray-800;
							color: $gray-100;*/
						}
						&:hover{
							/*background-color: $gray-700;
							color: $gray-100;*/
							&::after{
								color: $gray-100;
							}
						}
						&:active,
						&:focus,
						&.active{
							/*background-color: $gray-700;
							color: $gray-100;*/
							&::after{
								color: $gray-900;
							}
						}
					}
				}
			}
			.dropdown-menu-right{
				text-align: right;
			}
		}
	}
	.ticker{
		display: none;
		@include media-breakpoint-up(xl){
			display: block;
		}
		.carousel{
			span:first-of-type{
				a:link,
				a:visited,
				a:hover,
				a:active{
					color: inherit;
				}
			}
			span:last-of-type{
				background-color: $white;
				text-transform: none;
				font-weight: normal;
				a:link,
				a:visited,
				a:hover,
				a:active{
					color: $gray-900;
				}
			}
		}
	}
	.storage{
		display: none;
		@include media-breakpoint-up(sm){
			display: block;
		}
		.shopping{
			margin-right: .5rem;
		}
	}
	.dropdown-menu{
		margin-top: 1rem;
		border-color: $gray-600;
		background-color: $gray-900;
		a:link,
		a:visited{
			color: $gray-100;
		}
		a:hover,
		a:active,
		&.active{
			background-color: $gray-100;
			color: $gray-900;
		}
	}
	a:link,
	a:visited,
	a:hover,
	a:active{
		text-decoration: none;
	}
}

.home{
	.navbar{
		background-color: $white;
		.ticker{
			span{
				&:first-of-type{
					a:link,
					a:visited,
					a:hover,
					a:active{
						color: $white;
					}
				}
				&:last-of-type{
					background-color: $gray-100;
				}
			}
		}
	}
}

nav{
	ul.nav{
		&.in-page{
			.nav-item{
				margin-bottom: .5rem;
				&:last-of-type{
					margin-bottom: 0;
				}
			}
		}
	}
}