.folded{
	position: relative;
	overflow: visible;
	&.top-left{
		clip-path: polygon(1.5rem 0, 100% 0, 100% 100%, 0 100%, 0 1.5rem);
		&:after{
			top: 0;
			left: 0;
			border-radius: 0 0 $border-radius 0;
		}
	}
	&.top-right{
		clip-path: polygon(0 0, calc(100% - 1.5rem) 0, 100% 1.5rem, 100% 100%, 0 100%);
		&:after{
			top: 0;
			right: 0;
			border-radius: 0 0 0 $border-radius;
		}
	}
	&.bottom-left{
		clip-path: polygon(0 0, 100% 0, 100% 100%, 1.5rem 100%, 0 calc(100% - 1.5rem));
		&:after{
			bottom: 0;
			left: 0;
			border-radius: 0 $border-radius 0 0;
		}
	}
	&.bottom-right{
		clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1.5rem), calc(100% - 1.5rem) 100%, 0% 100%);
		&:after{
			bottom: 0;
			right: 0;
			border-radius: $border-radius 0 0 0;
		}
	}
	&.daytime{
		&:after{
			@include themify{
				background-color: map-get($daytime, 'background-color');
			}
		}
	}
	&.light{
		&:after{
			@include themify{
				background-color: map-get($light, 'background-color');
			}
		}
	}
	&.dark{
		&:after{
			@include themify{
				background-color: map-get($dark, 'background-color');
			}
		}
	}
	&.nighttime{
		&:after{
			@include themify{
				background-color: map-get($nighttime, 'background-color');
			}
		}
	}
	&:after{
		content: '';
		position: absolute;
		z-index: 1000;
		width: 1.5rem;
		height: 1.5rem;
		//box-shadow: 5px 5px 5px rgba($gray-900, .15); 
	}
}