#filter{
	.controls,
	.inputs{
		margin-bottom: -.5rem;
	}

	.controls{
		fieldset{
			margin-bottom: .5rem;
			&:last-child{
				margin-bottom: 0;
			}
			.filter-group{
				.btn{
					margin-bottom: .5rem;
				}
				&.mixitup{
					display: inline-block;
					border-radius: $border-radius;
					@include media-breakpoint-up(sm){
						padding: .25rem;
						background-color: $gray-200;
					}
					> div{
						margin-bottom: -.5rem;
					}
					.btn{
						margin-bottom: .5rem;
						color: $gray-900;
						&.btn-prepend{
							background-color: $gray-900;
							color: $white;
						}
						&.mixitup-control-active{
							background-color: $gray-900;
							color: $white;
						}
						&[data-type='reset-filter']{
							display: none;
						}
					}
				}
			}
		}
	}

	.inputs{
		fieldset{
			.form-group{
				margin-bottom: 0;
				background-color: $gray-200;
				border-top: 3px solid $gray-900;
				border-radius: $border-radius;
				padding: .25rem;
				label{
					display: none;
				}
				input[type=text]{
					border: 0 none transparent;
					background-color: $white;
					color: $gray-900;
					font-weight: 800;
					text-align: center;
					caret-color: transparent;
				}
			}
		}
	}
}