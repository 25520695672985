.choices[data-type*=select-one]{
  select{
	position: absolute !important;
    bottom: 0;
    left: 50%;
    display: block !important;
    width: 0 !important;
    height: 100% !important;
    padding: 0 !important;
    opacity: 0 !important;
    border: none;
    z-index: 0 !important;
  }
}




//.alt-form{
	.choices{
		margin-bottom: 0;
		font-size: 1rem;

		&.is-disabled{
			.choices__inner,
			.choices__input{
				background-color: $gray-200;
			}
		}
		&[data-type*=select-one]{
			.choices__inner{
				padding-bottom: .25rem;
			}
			.choices__input{
				padding: .5rem .75rem;
				border-bottom: 0 none transparent;
				background-color: $gray-200;
				height: auto;
			}
			&::after{
				border-color: $gray-800 transparent transparent transparent;
				right: .5rem;
			}
			&.is-open::after {
				border-color: transparent transparent $gray-800 transparent;
			}
		}

		.choices__list--single {
			padding: 0;
		}

		.choices__list--dropdown {
				visibility: hidden;
				visibility: visible;
				z-index: 100;
			background-color: $gray-200;
			border: 0 none transparent;
			margin-top: 0;
			border-bottom-left-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
			.choices__list {
				max-height: 15rem;
			}
			.choices__item {
				padding: .5rem .75rem;
				font-size: 1rem;
				font-weight: 800;
				color: $gray-900;
			}

			.choices__item--disabled {
				cursor: not-allowed;
				opacity: 0.65;
			}

			.choices__item--selectable.is-highlighted {
				background-color: $gray-600;
				color: $gray-200;
			}
		}

		.choices__placeholder {
			opacity: 1;
			color: $gray-600;
		}

		.choices__input {
			display: inline-block;
			vertical-align: baseline;
					background-color: $gray-200 !important;
					font-size: 1rem;
					margin-bottom: .5rem;
					padding: .5rem .75rem;
			}

		.choices__inner {
			background-color: $gray-200;
			padding: .25rem .5rem .25rem .75rem;
			border: 0 none transparent;
			border-radius: $border-radius;
			font-size: 1rem;
					font-weight: 800;
					color: $gray-900;
			min-height: auto;
		}

		.is-focused,
		.is-open{
			.choices__inner{
				border-color: transparent;
			}
		}
		.is-open{
			.choices__inner{
				border-radius: $border-radius $border-radius 0 0;
			}

			.choices__list--dropdown {
				border-color: $gray-200;
			}
		}
		.is-flipped{
			.choices__list--dropdown {
				margin-bottom: 0;
				border-radius: $border-radius $border-radius 0 0;
			}
			&.is-open{
				.choices__inner{
					border-radius: 0 0 $border-radius $border-radius;
				}
			}
		}
		@media (min-width: 640px) {
			.choices__list--dropdown .choices__item--selectable {
					padding-right: 0;
			}
			.choices__list--dropdown .choices__item--selectable::after {
					content: '';
					font-size: 1rem;
			}
		}
	}
//}