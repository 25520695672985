.offcanvas{
	transition: transform .5s ease-in-out;
	/*&.left{
		transform: translate3d(-24rem, 0, 0);
		width: 24rem;
	}*/
	&.right{
		background-color: $gray-900;
		-webkit-box-shadow: inset 10px 0px 15px -10px $black;
		box-shadow: inset 10px 0px 15px -10px $black;
		width: 24rem;
		transform: translate3d(24rem, 0, 0);
	}
}
.offcanvas-content{
	transition: transform .5s ease-in-out;
	-webkit-perspective: 1000;
	-webkit-backface-visibility: hidden;
}
.offcanvas-overlay {
	background: rgba($gray-900, .25);
	transition: opacity .5s ease-in-out, visibility .5s ease-in-out;
}
.offcanvas-is-open{
	/*&.left{
		.offcanvas-content{
			transform: translate3d(24rem, 0, 0);
		}
	}*/
	&.shopping{
		.offcanvas-content{
			transform: translate3d(-24rem, 0, 0);
		}
	}
}

.offcanvas.open{
	transform: translate3d(0, 0, 0);
}

div#shopping-comparison{
	transition: all .5s ease-in-out;
	opacity: 1;
}

.offcanvas-is-open{
	div#shopping-comparison{
		transform: translate3d(-24rem, 0, 0);
		opacity: 0;
		pointer-events: none;
	}
}