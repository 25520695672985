.carousel{
	&.vertical{
		.carousel-item-next.carousel-item-left,
		.carousel-item-prev.carousel-item-right{
			transform: translate3d(0, 0, 0);
		}
		.carousel-item-next,
		.active.carousel-item-right{
			transform: translate3d(0, 100%, 0);
		}
		.carousel-item-prev,
		.active.carousel-item-left{
			transform: translate3d(0, -100%, 0);
		}
	}
}

/*.carousel-inner:after{
	transition: all 2.5s ease;
}*/