img{
	&.fluid{
		max-width: 100%;
	}
	&.pretty{
		border-radius: $border-radius;
		@include themify{
			border-top: 3px solid map-get($primary, 'background-color');
		}
	}
	&.shadow{
		box-shadow: 0 0 2rem -.5rem rgba($gray-900, 0.125);
	}
}

iframe{
	border: 0 none transparent;
	&.movie{
		box-sizing: content-box;
		border-radius: $border-radius;
		@include themify{
			border-top: 3px solid map-get($primary, 'background-color');
		}
	}
}

.teaser{
	.image{
		&.illustration{
			display: none;
			@include media-breakpoint-up(md){
				display: inline;
				margin-top: -3rem;
			}
			@include media-breakpoint-up(lg){
				margin-top: -9rem;
			}
			&.xs{
				width: 15rem;
			}
			&.sm{
				width: 21rem;
			}
			&.md{
				width: 24rem;
			}
			&.lg{
				width: 32rem;
			}
			&.xl{
				width: 36rem;
			}
			&.float-right{
				float: none !important;
				margin-bottom: 1.5rem;
				display: block;
				margin-left: auto;
				margin-right: auto;
				@include media-breakpoint-up(md){
					float: right !important;
					margin-left: 1.5rem;
				}
			}
		}
	}
}

/*.sneeuwkettingenhuren{
	.teaser{
		.image{
			&.illustration{
				@include media-breakpoint-up(md){
					margin-top: -3rem;
				}
				@include media-breakpoint-up(lg){
					margin-top: -9rem;
				}
			}
		}
	}
}*/

.imagery{
	.imagery-pager,
	.imagery-media{
		ul{
			list-style-type: none;
			padding-left: 0;
		}
	}
	.imagery-pager{
		margin-bottom: 1.5rem;
		ul{
			li{
				display: inline;
			}
		}
		button{
			&.active{
				background-color: $gray-200;
				color: $gray-800;
			}
		}
	}
	.imagery-media{
		ul{
			li{
				&.imagery-media-item{
					display: none;
					border-radius: $border-radius;
					background-image: linear-gradient(to bottom, $gray-500 0, transparent 100%);
					&:hover{
						background-color: rgba($gray-500, 1);
					}
					img{
						opacity: .90;
					}
					&.show,
					&:first-child{
						&[data-imagery-media-type="picture"]{
							display: inline-block;
						}
						&[data-imagery-media-type="movie"]{
							display: block;
						}
					}
					&.hide{
						&[data-imagery-media-type="picture"],
						&[data-imagery-media-type="movie"]{
							display: none;
						}
					}
				}
			}
		}
	}
}

.content{
	.afbeelding,
	.image,
	.film{
		margin-bottom: 1.5rem;
	}
	.image,
	.afbeelding{
		&.illustration,
		&.illustratie{
			float: right;
			margin-left: 1.5rem;
			.vlag{
				width: 6rem;
				border-radius: $border-radius;
				box-shadow: 0 0 2rem -.5rem rgba($gray-900, 0.125);
			}
		}
	}
}

p.continue-reading{
	//color: $gray-500;
	span:last-of-type{
		//background-color: $gray-200;
		//color: $gray-900;
	}
}