.modal{
	&.modal-success{
		.modal-header{
			@include themify{
				background-color: map-get($success, 'background-color');
				color: map-get($success, 'color');
			}
		}
	}
	&.modal-warning{
		.modal-header{
			@include themify{
				background-color: map-get($warning, 'background-color');
				color: map-get($warning, 'color');
			}
		}
	}
	&.modal-danger{
		.modal-header{
			@include themify{
				background-color: map-get($danger, 'background-color');
				color: map-get($danger, 'color');
			}
		}
	}
	&.modal-info,
	&.modal-configuration{
		.modal-header{
			@include themify{
				background-color: map-get($nighttime, 'background-color');
				color: map-get($nighttime, 'color');
			}
		}
	}

	.modal-configuration{
		.modal-body{
			.bootstrap-select{
				width: 100% !important;
			}
		}
	}

	&.modal-asynchronous{
		.modal-body{
			border-bottom-left-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}
	}
	/*&.product{
		h1,
		h2,
		h3,
		h4,
		h5,
		h6{
			margin-bottom: inherit;
			font-family: inherit;
			font-weight: inherit;
			text-transform: inherit;
			text-rendering: inherit;
			font-size: inherit;
			&::after{
				content: none;
			}
		}
	}*/
	.modal-content{
		border: 0 none transparent;
		box-shadow: 0 0 3rem -.5rem rgba($gray-900, .5);
	}
	.modal-header{
		padding: .75rem 1.25rem;
		.close{
			opacity: 1;
			color: inherit;
			text-shadow: none;
			&:not(:disabled):not(.disabled):hover,
			&:not(:disabled):not(.disabled):focus{
				opacity: 1;
			}
		}
	}
	.modal-body{
		//padding: 1.25rem;
		padding: 1.5rem;
		form{
			margin-bottom: 0;
		}
		.afbeelding{
			&.illustratie{
				img{
					max-width: 15rem;
					@include media-breakpoint-up(xl){
						max-width: 30rem;
					}
				}
			}
		}
	}
	.modal-footer{
		border-top: 0 none transparent;
		border-bottom: 3px solid $gray-900;
		background-color: $gray-200;
	}
	.product{
		box-shadow: none;
	}
}