.modal-comparison{
	.modal-content{
		position: relative;
		border: 0 solid transparent;
		background: transparent;
		/*&::before,
		&::after{
			content: ' ';
			position: absolute;
			z-index: -1;
			height: 97.5%;
			width: 100%;
			border-radius: $border-radius;
			background-color: $gray-100;
			border-top: 3rem solid $gray-400;
			border-bottom: 3px solid $gray-400;
			box-shadow: 0 0 2rem -.5rem rgba($gray-900, 0.25);
		}
		&::before {
			top: .75rem;
			right: .5rem;
			//transform: rotate(-.5deg);
		}
		&::after {
			top: .75rem;
			left: .5rem;
			//transform: rotate(.5deg);
		}*/
		/*article.product{
			width: 100%;
			box-shadow: 0 0 2rem -.5rem rgba($gray-900, 0.125);
		}*/
	}
	.modal-body{
		padding: 0;
		background-color: transparent;
	}
	.comparison{
		position: relative;
		.row{
			margin-right: 0;
			margin-left: 0;
		}
		.product{
			margin-bottom: 0;
			.card-header{
				.name{
					.badge{
						background-color: $gray-900 !important;
						color: $white !important;
						&.merk{
							background-color: $gray-900 !important;
							color: $white !important;
						}
						&.model{
							background-color: $white !important;
							color: $gray-900 !important;
						}
					}
				}
				.actions{
					display: block;
					button{
						border: 0 none transparent;
						background-color: $white;
						color: $gray-900;
						span{
							display: none;
						}
						/*&.previous{
							i{
								float: left;
							}
						}
						&.next{
							i{
								float: right;
							}
						}*/
					}
					&.disabled{
						button{
							opacity: 1;
							color: $gray-500;
							cursor: not-allowed;
						}
					}
				}
			}
			.card-img{
				background-image: linear-gradient(to bottom, $gray-200 0, $white 100%);
				a{
					display: block;
					text-align: center;
					margin: 0 auto;
				}
				img{
					min-height: 150px;
					opacity: .90;
				}
				.properties{
					display: block;
				}
			}
		}
		@include media-breakpoint-up(sm){
			.product{
				width: $modal-md;
			}
		}
		@include media-breakpoint-up(lg){
			.product{
				width: $modal-lg;
			}
		}
		@include media-breakpoint-up(xl){
			.product{
				width: $modal-xl;
			}
		}
	}
}