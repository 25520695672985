.map{
	h3,
	p{
		margin: inherit;
		font-family: Inconsolata, 'Courier New', Courier, monospace;
		font-size: 1rem;
		line-height: 1.5;
	}
	h3,
	a{
		@include themify{
			color: map-get($accent-primary, 'background-color');
		}
		&::after{
			content: none;
		}
	}
	iframe{
		width: 100%;
	}
}