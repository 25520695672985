@import 'bootstrap';
@import 'overrides';
@import 'theme-engine';
@import 'typography';
@import 'packages';
@import 'components';
@import 'modules';
@import 'sites';
@import 'debug';
@import 'dev';

body{
	background-color: $white;
	&.home{
		background-color: $gray-100;
		footer{
			background-color: $white;
		}
	}
	&[class*="errors/"]{
		.introduction{
			margin-bottom: 0;
		}
	}
}

main#container{
	padding-top: 4.5rem;
	header{
		margin-top: 1.5rem;
		margin-bottom: 3rem;
	}
}

.container-inline{
	margin-bottom: 1.5rem;
}