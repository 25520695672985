.daterangepicker{
	width: 300px;
	margin-top: .5rem;
	border: 1px solid $gray-400;
	background-color: $white;
	border-radius: $border-radius;
	font-family: inherit;
	font-size: inherit;
	line-height: 1em;
	&:before,
	&:after{
		top: 0;
		border: 0 none transparent;
	}
	.drp-calendar{
		max-width: 250px;
		&.left,
		&.right{
			padding: .5rem;
		}
	}
	.calendar-table{
		border: 1px solid $white;
		border-radius: $border-radius;
		background-color: $white;

		.prev span,
		.next span{
			color: $white;
			border: solid $gray-900;
			border-width: 0 2px 2px 0;
		}
		.prev span{
			transform: rotate(135deg);
		}
		.next span{
			transform: rotate(-45deg);
		}
		th,
		td{
			width: 2rem;
			min-width: 2rem;
			height: 2rem;
			border: 0 none transparent;
			line-height: inherit;
			font-size: 1rem;
			border-radius: $border-radius;
			&.available{
				&:hover{
					background-color: $gray-200;
					border-color: transparent;
					color: inherit;
				}
			}
		}
		td{
			&.in-range{
				background-color: $gray-200;
				color: inherit;
				border-radius: 0;
			}
			
			&.start-date,
			&.end-date,
			&.active{
				@include themify{
					background-color: map-get($secondary, 'background-color');
					color: map-get($secondary, 'color');
				}
				&:hover{
					@include themify{
						color: map-get($secondary, 'color');
					}
				}
			}
			&.start-date{
				border-radius: $border-radius 0 0 $border-radius;
			}
			
			&.end-date{
				border-radius: 0 $border-radius $border-radius 0;
			}
			
			&.start-date.end-date{
				border-radius: $border-radius;
			}
			&.off,
			&.off.in-range,
			&.off.start-date,
			&.off.end-date{
				background-color: $white;
				color: $gray-600;
			}
			&.disabled{
				color: $gray-600;
				text-decoration: line-through;
			}
		}
	}
	.drp-buttons{
		padding: .5rem;
		border-top-color: $gray-400;
		line-height: 1.5rem;
		.btn{
			margin-left: .5rem;
			font-size: inherit;
			font-weight: inherit;
			padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
		}
	}
	.drp-selected{
		font-size: inherit;
		padding-right: .5rem;
	}
}

@include media-breakpoint-up(sm){
	.daterangepicker{
		width: auto;
		direction: ltr;
		text-align: left;
		.drp-calendar{
			&.left{
				clear: left;
				margin-right: 0;
				.calendar-table{
					border-right: none;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					padding-right: .5rem;
				}
			}
			&.right{
				margin-left: 0;
				.calendar-table{
					border-left: none;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}
	}
}