.blog{
	article{
		footer{
			margin-bottom: 1.5rem;
			color: $gray-500;
		}
	}
	.blog-list{
		article{
			margin-bottom: 3rem;
		}
	}
	.pager{
		.previous,
		.next{
			display: none;
			@include media-breakpoint-up(md){
				display: block;
			}
		}
	}
	dl.tags{
		p{
			display: inline;
		}
	}
}