$ignore-warning: true !default;
//$yiq-contrasted-threshold: 0 !default;

$brand-sneeuwkettingenhuren: #EB5A41;
$brand-dakkofferhuren: #375FAA;
$brand-fietsdragerhuren: #FAB43C;

$themes: (
	'sneeuwkettingenhuren': (
		'brand': (
			'background-color': #EB5A41,
			'color': $white,
		),
		'accent-primary': (
			'background-color': #EB5A41,
			'color': $white,
		),
		'accent-secondary': (
			'background-color': #375FAA,
			'color': $white,
		),
		'accent-tertiary': (
			'background-color': #FAB43C,
			'color': $white,
		),
		'primary': (
			'background-color': #EB5A41,
			'color': $white
		),
		'secondary': (
			'background-color': #F08371,
			'color': $white
		),
		'tertiary': (
			'background-color': #FAD6CF,
			'color': $white
		),
		'success': (
			'background-color': #28A546,
			'color': $white
		), 
		'danger': (
			'background-color': #EB5A41,
			'color': $white
		),
		'error': (
			'background-color': #EB5A41,
			'color': $white
		),
		'warning': (
			'background-color': #FAB43C,
			'color': $white
		),
		'info': (
			'background-color': #375FAA,
			'color': $white
		),
		'daytime': (
			'background-color': $white,
			'color': $gray-900
		),
		'light': (
			'background-color': $gray-100,
			'color': $gray-800
		),
		'dark': (
			'background-color': $gray-800,
			'color': $white
		),
		'nighttime': (
			'background-color': $gray-900,
			'color': $white
		)
	),
	'dakkofferhuren': (
		'brand': (
			'background-color': #375FAA,
			'color': $white,
		),
		'accent-primary': (
			'background-color': #375FAA,
			'color': $white,
		),
		'accent-secondary': (
			'background-color': #FAB43C,
			'color': $white,
		),
		'accent-tertiary': (
			'background-color': #EB5A41,
			'color': $white,
		),
		'primary': (
			'background-color': #375FAA,
			'color': $white
		),
		'secondary': (
			'background-color': #6987BF,
			'color': $white
		),
		'tertiary': (
			'background-color': #CDD7EA,
			'color': $white
		),
		'success': (
			'background-color': #28A546,
			'color': $white
		), 
		'danger': (
			'background-color': #EB5A41,
			'color': $white
		),
		'error': (
			'background-color': #EB5A41,
			'color': $white
		),
		'warning': (
			'background-color': #FAB43C,
			'color': $white
		),
		'info': (
			'background-color': #375FAA,
			'color': $white
		),
		'daytime': (
			'background-color': $white,
			'color': $gray-900
		),
		'light': (
			'background-color': $gray-100,
			'color': $gray-800
		),
		'dark': (
			'background-color': $gray-800,
			'color': $white
		),
		'nighttime': (
			'background-color': $gray-900,
			'color': $white
		)
	),
	'fietsdragerhuren': (
		'brand': (
			'background-color': #FAB43C,
			'color': $white,
		),
		'accent-primary': (
			'background-color': #FAB43C,
			'color': $white,
		),
		'accent-secondary': (
			'background-color': #EB5A41,
			'color': $white,
		),
		'accent-tertiary': (
			'background-color': #375FAA,
			'color': $white,
		),
		'primary': (
			'background-color': #FAB43C,
			'color': $white
		),
		'secondary': (
			'background-color': #FBC76D,
			'color': $white
		),
		'tertiary': (
			'background-color': #FEECCE,
			'color': $white
		),
		'success': (
			'background-color': #28A546,
			'color': $white
		), 
		'danger': (
			'background-color': #EB5A41,
			'color': $white
		),
		'error': (
			'background-color': #EB5A41,
			'color': $white
		),
		'warning': (
			'background-color': #FAB43C,
			'color': $white
		),
		'info': (
			'background-color': #375FAA,
			'color': $white
		),
		'daytime': (
			'background-color': $white,
			'color': $gray-900
		),
		'light': (
			'background-color': $gray-100,
			'color': $gray-800
		),
		'dark': (
			'background-color': $gray-800,
			'color': $white
		),
		'nighttime': (
			'background-color': $gray-900,
			'color': $white
		)
	)
);

$helper-classes:
	'primary',
	'secondary',
	'tertiary',
	'accent-primary',
	'accent-secondary',
	'accent-tertiary',
	'success',
	'danger',
	'error',
	'warning',
	'info',
	'light',
	'dark',
	'daytime',
	'nighttime'
;

// Stel alle variabelen op 'null' in, zodat deze geen waarde teruggeven wanneer ze buiten de 'themify'-functie gebruikt zouden worden
$accent-primary: null;
$accent-secondary: null;
$accent-tertiary: null;

$primary: null;
$secondary: null;
$success: null;
$danger: null;
$error: null;
$warning: null;
$info: null;
$light: null;
$dark: null;
$daytime: null;
$nighttime: null;

@mixin themify($themes: $themes){
	// Lus over de thema's
	@each $theme, $colors in $themes{
		// Maak de 'selector' aan, zoals '.klasse.themanaam' of '.themanaam .klasse'
		.#{$theme} &{
			$accent-primary: map-get($colors, 'accent-primary') !global;
			$accent-secondary: map-get($colors, 'accent-secondary') !global;
			$accent-tertiary: map-get($colors, 'accent-tertiary') !global;

			$primary: map-get($colors, 'primary') !global;
			$secondary: map-get($colors, 'secondary') !global;
			$tertiary: map-get($colors, 'tertiary') !global;
			$success: map-get($colors, 'success') !global;
			$danger: map-get($colors, 'danger') !global;
			$error: map-get($colors, 'danger') !global;
			$warning: map-get($colors, 'warning') !global;
			$info: map-get($colors, 'info') !global;
			$light: map-get($colors, 'light') !global;
			$dark: map-get($colors, 'dark') !global;
			$daytime: map-get($colors, 'daytime') !global;
			$nighttime: map-get($colors, 'nighttime') !global;

			// Schrijf de code
			@content;

			// Stel de variabelen terug op 'null'-waarden in
			$accent-primary: null !global;
			$accent-secondary: null !global;
			$accent-tertiary: null !global;

			$primary: null !global;
			$secondary: null !global;
			$tertiary: null !global;
			$success: null !global;
			$danger: null !global;
			$error: null !global;
			$warning: null !global;
			$info: null !global;
			$light: null !global;
			$dark: null !global;
			$daytime: null !global;
			$nighttime: null !global;
		}
	}
}

// Lus over de themakleuren en herschrijf de stijlen
@each $theme, $colors in $themes{
	@each $helper-class in $helper-classes{
		html.#{$theme}{
			.btn.btn-#{$helper-class}{
				@include button-variant(map-get(map-get($colors, $helper-class), 'background-color'), map-get(map-get($colors, $helper-class), 'background-color'));
				color: map-get(map-get($colors, $helper-class), 'color');
				&:hover,
				&:not(:disabled):not(.disabled):active,
				&:focus{
					color: map-get(map-get($colors, $helper-class), 'color');
				}
			}

			.alert.alert-#{$helper-class},
			.toast.toast-#{$helper-class}{
				background-color: map-get(map-get($colors, $helper-class), 'background-color');
				color: map-get(map-get($colors, $helper-class), 'color');
				.badge{
					background-color: map-get(map-get($colors, $helper-class), 'color');
					color: map-get(map-get($colors, $helper-class), 'background-color');
				}
				.btn.btn-primary{
					@include button-variant(map-get(map-get($colors, $helper-class), 'color'), map-get(map-get($colors, $helper-class), 'background-color'));
					color: map-get(map-get($colors, $helper-class), 'background-color');
					&:hover,
					&:not(:disabled):not(.disabled):active,
					&:focus{
						color: map-get(map-get($colors, $helper-class), 'color');
					}
					//background-color: map-get(map-get($colors, $helper-class), 'color');
					//color: map-get(map-get($colors, $helper-class), 'background-color');
				}
			}

			.badge.badge-#{$helper-class}{
				background-color: map-get(map-get($colors, $helper-class), 'background-color');
				color: map-get(map-get($colors, $helper-class), 'color');
			}
		}
	}
}