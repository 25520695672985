.bootstrap-select{
	.dropdown-menu{
		/*transform: none !important;
		margin-top: 0;
		z-index: 1050;
		border-color: $gray-400;
		padding: 0;*/
	}
	.dropdown-item{
		font-weight: normal !important;
		text-transform: none !important;
		&:hover{
			background-color: $gray-200;
		}
		&.disabled{
			color: $gray-500;
		}
		&.active{
			@include themify{
				background-color: map-get($dark, 'background-color');
				color: map-get($dark, 'color');
			}
		}
		a:link,
		a:visited,
		a:hover,
		a:active,
		a:not([href]){
			color: $gray-900;
		}
	}
	.bs-searchbox,
	.bs-actionsbox,
	.bs-donebutton{
		//padding: 1.25rem;
		padding: 1.5rem;
	}
}
.dropdown-menu.show{
	display: block !important;
}