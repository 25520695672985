.container {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.navbar{
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.modal-dialog {
	.modal.modal-static & {
		transform: translate(0, -50px) !important;
	}
}
.modal-content{
	border-radius: $border-radius;
	background-color: transparent;
}
.modal-header{
	border-top-left-radius: $border-radius;
	border-top-right-radius: $border-radius;
}
.modal-body{
	background-color: $white;
}

:focus{
	box-shadow: none !important;
	outline: none !important;
}
.btn,
.bootstrap-select .dropdown,
.bootstrap-select .dropdown-toggle{
	&:focus,
	&.focus{
		box-shadow: none !important;
		outline: none !important;
	}
}
.btn-lg {
	border-radius: $border-radius;
}

.navbar-expand{
	.navbar-nav{
		.nav-link{
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.tooltip{
	font-family: inherit;
	font-size: inherit;
	font-weight: 700;
}

.invalid-feedback{
	font-size: inherit;
}

.badge{
	padding: .25em .5em;
}