.btn-badge{
	&.prefix{
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	&.infix{
		border-radius: 0;
	}
	&.suffix{
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.btn-action{
	position: relative;
	.action{
		position: absolute;
		top: -.75rem;
		right: -.75rem;
		background-color: $gray-900;
		color: $white;
		animation-name: btn-action;
		animation-duration: 1.5s;
		animation-iteration-count: infinite;
	}
}

.btn-cloaked{
	border: 0 none transparent;
	padding: 0;
}