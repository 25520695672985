/*button.remove{
	border: 0 none transparent;
	span.confirmation{
		display: none;
	}
}*/

.boodschappenlijst{
	margin-bottom: 1.5rem;
	border: 0 solid transparent;
	border-radius: 0;
	background-color: transparent;
	.card-header{
		padding: 1rem 0;
		border-bottom: 0 solid transparent;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		background-color: $white;
		background-color: $gray-100;
	}
	.card-body{
		&.first{
			padding: 1.5rem 1.5rem 0;
			background-color: $white;
			background-image: linear-gradient(0deg, $white 0%, $gray-100 100%);
			background-size: 100% 6rem;
			background-position: top;
			background-repeat: no-repeat;
		}
		&.last{
			padding: 1.5rem 1.5rem 3rem;
			background-color: $white;
		}
	}
	.card-footer{
		padding: 1.5rem;
		background-color: $gray-200;
		margin-top: -1.5rem;
		&:after{
			background-color: $gray-400 !important;
		}
	}
	.list-group,
	.list-group-item{
		background-color: transparent;
	}
	.list-group{
		border-radius: 0;
	}
	.list-group-item{
		padding: 0;
		border: 0 none transparent;
		&:last-of-type{
			dl{
				margin-bottom: 0;
			}
		}
	}
	dl{
		dd{
			&:before{
				content: '└';
			}
		}
	}
}