#toast-container{
	position: fixed;
	z-index: $zindex-popover;
	& > div{
		position: relative;
		overflow: hidden;
		padding: 1.5rem;
		width: 18rem;
		opacity: 1;
		@include media-breakpoint-up(sm){
			width: 24rem;
		}
	}
}

.toast-top-left{
	top: 1.5rem;
	left: 1.5rem;
}
.toast-top-right{
	top: 1.5rem;
	right: 1.5rem;
}
.toast-bottom-left{
	bottom: 1.5rem;
	left: 1.5rem;
}
.toast-bottom-right{
	right: 1.5rem;
	bottom: 1.5rem;
}
.toast-top-center,
.toast-bottom-center,
.toast-top-full-width,
.toast-bottom-full-width{
	width: 100%;
}
.toast-top-center{
	top: 0;
	right: 0;
}
.toast-bottom-center{
	bottom: 0;
	right: 0;
}
.toast-top-full-width{
	top: 0;
	right: 0;
}
.toast-bottom-full-width{
	bottom: 0;
	right: 0;
}

.toast{
	font-size: 1rem;
	border: 0 none transparent;
	@include border-radius($border-radius);
	.toast-title{
		@extend .badge;
		margin-bottom: 1.5rem;
	}
	.toast-message{
		a:link,
		a:visited,
		a:hover,
		a:active{
			color: $white;
			text-decoration: underline;
		}
		p:last-of-type{
			margin-bottom: 0;
		}
	}
	.toast-close-button{
		position: relative;
		right: -0.25em;
		top: -0.25em;
		float: right;
		padding: 0;
		border: 0 none transparent;
		background: transparent;
		color: inherit;
		font-size: 1rem;
		font-weight: normal;
		-webkit-appearance: none;
	}
}

.toast-progress{
	position: absolute;
	left: 0;
	bottom: 0;
	height: 3px;
	background-color: $white;
	opacity: 0.5 !important;
}