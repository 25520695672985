.paper{
	position: relative;
	z-index: 10;
	padding: .25rem;
	&,
	&::before,
	&::after{
		box-shadow: 0 0 .25rem 0 rgba($gray-900, .125);
		border-radius: $border-radius;
	}
	&::before,
	&::after{
		content: '';
		position: absolute;
		height: 100%;
		width: 100%;
		border-bottom: 3px solid $gray-400;
		background-color: $gray-100;
	}
	&::before{
		right: 10px;
		top: -5px;
		transform: rotate(-1deg);
		z-index: -1;
	}
	&::after {
		top: 5px;
		right: -10px;
		transform: rotate(1deg);
		z-index: -2;
	}
}