#shopping-comparison{
	position: fixed;
	bottom: 1.5rem;
	right: 1.5rem;
	z-index: $zindex-fixed;
	> div{
		border-radius: $border-radius;
		box-shadow: 0 0 1.5rem 0 rgba($gray-900, .5);
		button{
			display: block;
			position: relative;
			margin-top: .5rem;
			padding: .5rem;
			font-size: 1rem;
			@include themify{
				border-bottom: 3px solid darken(map-get($accent-secondary, 'background-color'), 15%);
				box-shadow: inset 0 -3px darken(map-get($accent-secondary, 'background-color'), 15%);
				background-color: map-get($accent-secondary, 'background-color');
			}
			color: $white;
			cursor: pointer;
			@include media-breakpoint-up(md){
				margin-top: 1.5rem;
				padding: 1.5rem;
			}
			&:hover{
				@include themify{
					background-color: lighten(map-get($accent-secondary, 'background-color'), 15%);
					box-shadow: inset 0 -3px map-get($accent-secondary, 'background-color');
					border-bottom: 3px solid map-get($accent-secondary, 'background-color');
				}
			}
			.fa,
			.fas,
			.fab{
				font-size: 1.5rem;
			}
			.counter{
				position: absolute;
				top: -.75rem;
				left: -.75rem;
				@include themify{
					background-color: map-get($nighttime, 'background-color');
				}
			}
		}
	}
}

.confirmation{
	.badge{
		&:last-child{
			display: none;
		}
	}

	&.confirming{
		.badge{
			&:first-child{
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
			&:last-child{
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				display: inline-block;
			}
		}
	}
}