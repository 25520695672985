#filter{
	input[type="text"]{
		height: 3rem;
	}
}

// temp

.tab-content>.tab-pane{
	//display: block;
}


///////

.form-control:focus{
	background-color: $gray-200;
	color: $gray-900;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
	-webkit-box-shadow: 0 0 0 6rem $gray-200 inset !important;
}
::placeholder{
	opacity: 1;
	color: $gray-600;
}
:-ms-input-placeholder{
	opacity: 1;
	color: $gray-600;
}

::-ms-input-placeholder{
	opacity: 1;
	color: $gray-600;
}


.btn-group>.btn:not(:first-child), .btn-group>.btn-group:not(:first-child){
	margin-left: 0;
}


/*input:-internal-autofill-selected{
	background-color: red !important;
}*/







form{
	legend{
		display: none;
	}
	&#offerteaanvraag{
		margin-top: 1.5rem;
	}

	&.controls{
		margin-bottom: 0;
	}


	small{
		margin-bottom: 1.5rem;
	}

	.label{
		@extend .badge;
		background-color: $white;
		color: $dark;
		&:hover{
			color: inherit;
		}
	}	
}






























.form-group{
	margin-bottom: 1.5rem;

	label{
		background-color: $white;
		color: $gray-900;
		margin-bottom: 0;
	}
	textarea,
	input,
	input:focus{
		font-weight: bold;
		height: 1.5rem;
		padding: .25rem .5rem;
		border: 0 none transparent;
		background-color: $gray-200;
		color: $gray-900;
	}

	textarea,
	textarea:focus{
		height: 6rem;
		outline: none;
		resize: none;
	}

	input[type="text"],
	input[type="email"]{
		//background-color: green !important;
		border-top-left-radius: $border-radius !important;
		border-bottom-left-radius: $border-radius !important;
	}

	&.form-group-choices{
		.form-field{
			padding: 0;
			background-color: $white !important;
		}
		.choices{
			.choices__inner{
				border-top-left-radius: 0;
			}
			&.is-open{
				.choices__inner/*,
				.choices__list*/{
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
		}
	}

	&.stealth{
		label{
			display: none;
		}
	}

	&.actions{
		/*text-align: center;*/
	}
	

	.input-group{
		background-color: $gray-200;

		&.compact-horizontal{
			background-color: $gray-200;
			.btn{
				&.active{
					background-color: orange !important;
				}
			}
		}
	}
	.input-group-prepend{
		margin-right: 0;
	}

	.btn-group,
	.form-field{
		background-color: $gray-200;
	}








	.compact-horizontal,
	.compact-vertical{
		border-radius: $border-radius;
		padding: .25rem;
	}

	.compact-horizontal{
		.btn{
			&.active{
				background-color: $gray-800 !important;
				color: $white;
			}
		}
	}
	
	.compact-vertical{
		background-color: $gray-200;
		padding-bottom: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		display: inline-block;
		label{
			display: block;
		}
		.btn{
			&.active{
				background-color: $gray-800 !important;
				color: $white;
			}
		}
		.badge{
			background-color: $white;
			color: $gray-900;
		}
		~ .btn-group{
			border-top-left-radius: 0 !important;
		}
		.form-field{
			border-radius: $border-radius;
			border-top-left-radius: 0 !important;
		}
	}
}









.form-field{
	padding: .25rem .25rem;
	background-color: $gray-200;
}

.form-group{
	

	

	

	.btn-group{
		&.btn-group-multiline{
			//background-color: orange;
			padding-bottom: 0;
			&>.btn{
				//background-color: lime;
				display: inline-block;
				border-radius: .25rem;
				margin-right: .25rem;
				margin-bottom: .25rem;
			}
		}


		padding: .25rem;
		border-radius: $border-radius;
		display: block;
		overflow: hidden;
		> div{
			float: left;
			margin-right: .5rem;
		}
		&.opt-in-out{
			display: inline-block;
			.active{
				@include themify{
					background-color: map-get($success, 'background-color') !important;
					color: map-get($success, 'color') !important;
				}
			}
		}
	}

	
}





/* Stappen */
.form-steps{
	font-size: 0;
	.badge{
		font-size: 1rem;
	}

	.nav-item{
		display: inline-block;
		margin-right: .5rem;
		&:last-of-type{
			margin-right: 0;
		}

		.nav-link{
			margin: 0;
			padding: 0;
			.badge{
				&.step{
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					background-color: $gray-900;
					color: $white;
				}
				&.label{
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					background-color: $gray-200;
					color: $gray-900;
				}
			}
			&.disabled{
				cursor: not-allowed;
				.badge{
					&.step{
						background-color: $gray-900;
						color: $white;
					}
					&.label{
						background-color: $gray-200;
						color: $gray-900;
					}
				}
			}
			&.active{
				.badge{
					&.step{
						background-color: $gray-900;
						color: $white;
					}
					&.label{
						background-color: $gray-900;
						color: $white;
					}
				}
			}
			&.valid{
				.badge{
					&.step{
						@include themify{
							background-color: map-get($success, 'background-color');
							color: map-get($success, 'color');
						}
					}
				}
			}
			&.invalid{
				.badge{
					&.step{
						@include themify{
							background-color: map-get($danger, 'background-color');
							color: map-get($danger, 'color');
						}
					}
				}
			}
		}
	}
}

/* Invoervalidatie */
.field-error{
	*:not(.btn-group-toggle.multiple){
		.label{
			@include themify{
				background-color: map-get($danger, 'background-color');
				color: map-get($danger, 'color');
			}
		}
	}
}

/* Bijzonderheden */
.g-recaptcha {
	display: inline-block;
}