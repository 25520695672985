$transition-base: all .25s ease-in-out;
$transition-fade: opacity .25s ease-in-out;
$transition-collapse: height .25s ease-in-out;

$navbar-toggler-transition: box-shadow .25s ease-in-out;

$btn-transition: color .25s ease-in-out, background-color .25s ease-in-out, border-color .25s ease-in-out, box-shadow .25s ease-in-out;

$form-switch-transition: .25s ease-in-out;
$form-range-thumb-transition: background-color .25s ease-in-out, border-color .25s ease-in-out, box-shadow .25s ease-in-out;
$input-transition: border-color .25s ease-in-out, box-shadow .25s ease-in-out;

$modal-transition: transform .5s ease-in-out;

$carousel-transition-duration: .5s;
$carousel-transition: transform $carousel-transition-duration ease-in-out;
$carousel-control-transition: opacity .25s ease-in-out;
$carousel-indicator-transition: opacity .5s ease-in-out;

$progress-bar-animation-timing: 1s ease-in-out infinite;
$progress-bar-transition: width .5s ease-in-out;

$spinner-animation-speed: .75s;

@mixin animation-blink($name, $background-color, $color){
	@keyframes #{$name}{
		0%{
			background-color: inherit;
			color: inherit;
		}
		25%{
			background-color: $background-color;
			color: $color;
		}
		100%{
			background-color: inherit;
			color: inherit;
		}
	}
}

@include animation-blink(blink, inherit, $gray-500);
@include animation-blink(blink-daytime-nighttime, $gray-900, $white);
@include animation-blink(btn-action, $gray-900, $white);

@keyframes spin{
	from{
		transform:rotate(-270deg);
	}
	to{
		transform:rotate(0deg);
	}
}

.spin{
	animation-name: spin;
	animation-duration: 5000ms;
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
}

.breathe{
	&.in{
		@include themify{
			background-color: lighten(map-get($accent-secondary, 'background-color'), 15%) !important;
		}
	}
	&.out{
		@include themify{
			background-color: lighten(map-get($accent-secondary, 'background-color'), 0%) !important;
		}
	}
}

@keyframes flicker {
	0%, 19%, 22%, 62%, 64%, 70%, 100%{
		opacity: 0.99;
	}
	20%, 21%, 63%, 65%, 69%{
		opacity: 0.4;
	}
}

.flicker{
    animation: flicker 3s linear infinite;
}

.loading{
	margin-bottom: 0;
	& > span{
		animation-name: blink;
		animation-duration: 1.5s;
		animation-iteration-count: infinite;
		&:nth-child(2){
			animation-delay: .5s;
		}
		&:nth-child(3){
			animation-delay: 1s;
		}
	}
}

.collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
}