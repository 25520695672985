.sneeuwkettingenhuren{
	.product{
		&.sneeuwkettingen{
			&:hover{
				.card-img{
					img{
						filter: none;
					}
				}
			}
			.card-img{
				img{
					filter: grayscale(1);
				}
			}
		}
	}
}

.fietsdragerhuren{
	.bar{
		&.scenery{
			&.top{
				background-position: center 35%;
			}
		}
	}
}