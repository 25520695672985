/*.table-container{
    border-radius: $border-radius;
    @include themify{
        border-top: 3px solid map-get($primary, 'background-color');
    }
    //background-image: linear-gradient(to bottom, $gray-200 0, transparent 100%);
    box-shadow: 0 0 1.5rem 0 rgba($gray-500, .5);
}

.table{


    th{
        @include themify{
             background-color: map-get($tertiary, 'background-color');
        }
    }

    &.table-striped tbody tr:nth-of-type(odd){
        background-color: $gray-100;
    }
    &.table-striped tbody tr:nth-of-type(even){
        background-color: $white;
    }

    //th:empty, td:empty { display: none; }

    empty-cells: hide;
    border-radius: $border-radius;
    th,
    td{
        border-top: 0 none transparent !important;
        border-bottom: 0 none transparent !important;

        &.align-left{
            text-align: left;
        }
        &.align-center{
            text-align: center;
        }
        &.align-right{
            text-align: right;
        }

        &.cell-header{
            font-weight: bold;
        }
    }


    .table th, .table td {
        padding: 0.75rem;
        vertical-align: top;
        
    }
}



// Stacktable overrides

.st-key,
.st-val{
    width: 50%;
}

.st-key{
    text-align: right !important;
}
.st-val{
    text-align: left !important;
}











*/








.tile{
	width: 100%;
	background:#fff;
	border-radius:5px;
	box-shadow:0px 2px 3px -1px rgba(151, 171, 187, 0.7);
	float:left;
  	transform-style: preserve-3d;
  	margin: 10px 5px;

}

/*.header{
	border-bottom:1px solid #ebeff2;
	padding:19px 0;
	text-align:center;
	color:#59687f;
	font-size:600;
	font-size:19px;	
	position:relative;
}*/

/*

.banner-img {
	padding: 5px 5px 0;
}

.banner-img img {
	width: 100%;
	border-radius: 5px;
}

.dates{
	border:1px solid #ebeff2;
	border-radius:5px;
	padding:20px 0px;
	margin:10px 20px;
	font-size:16px;
	color:#5aadef;
	font-weight:600;	
	overflow:auto;
}
.dates div{
	float:left;
	width:50%;
	text-align:center;
	position:relative;
}
.dates strong,
.stats strong,
.stats .badge{
	display: inline-block;
	//color:#adb8c2;
	//font-size:11px;
	//font-weight:700;
}
.dates span{
	width:1px;
	height:40px;
	position:absolute;
	right:0;
	top:0;	
	background:#ebeff2;
}
.stats{
	border-top:1px solid #ebeff2;
	background:#f7f8fa;
	//overflow:auto;
	padding:15px 0;
	font-size:16px;
	color:#59687f;
	font-weight:600;
	border-radius: 0 0 5px 5px;
}
.stats div{
	//border-right:1px solid #ebeff2;
	//width: 33.33333%;
    //width: 20%;
	//float:left;
	text-align:center
}

.stats div:nth-of-type(3){border:none;}

div.footer {
	text-align: right;
	position: relative;
	margin: 20px 5px;
}

div.footer a.Cbtn{
	padding: 10px 25px;
	background-color: #DADADA;
	color: #666;
	margin: 10px 2px;
	text-transform: uppercase;
	font-weight: bold;
	text-decoration: none;
	border-radius: 3px;
}

div.footer a.Cbtn-primary{
	background-color: #5AADF2;
	color: #FFF;
}

div.footer a.Cbtn-primary:hover{
	background-color: #7dbef5;
}

div.footer a.Cbtn-danger{
	background-color: #fc5a5a;
	color: #FFF;
}

div.footer a.Cbtn-danger:hover{
	background-color: #fd7676;
}

*/