.adviezen,
.reeksen{
	&.scrutiny{
		.advies,
		.reeks{
			box-shadow: 0 0 1.5rem 0 rgba($gray-500, .5);

			&.accent-primary{
				footer,
				.icon{
					@include themify{
						background-image: linear-gradient(to bottom, map-get($accent-primary, 'background-color'), darken(map-get($accent-primary, 'background-color'), 10%));
						color: map-get($accent-primary, 'color');
					}
				}
				.card-footer{
					@include themify{
						border-bottom-color: map-get($accent-primary, 'background-color');
					}
				}
			}
			&.accent-secondary{
				footer,
				.icon{
					@include themify{
						background-image: linear-gradient(to bottom, map-get($accent-secondary, 'background-color'), darken(map-get($accent-secondary, 'background-color'), 10%));
						color: map-get($accent-secondary, 'color');
					}
				}
				.card-footer{
					@include themify{
						border-bottom-color: map-get($accent-secondary, 'background-color');
					}
				}
			}
			&.accent-tertiary{
				footer,
				.icon{
					@include themify{
						background-image: linear-gradient(to bottom, map-get($accent-tertiary, 'background-color'), darken(map-get($accent-tertiary, 'background-color'), 10%));
						color: map-get($accent-tertiary, 'color');
					}
				}
				.card-footer{
					@include themify{
						border-bottom-color: map-get($accent-tertiary, 'background-color');
					}
				}
			}
			&:hover{
				footer{
					i{
						transform: scale(1.25);
					}
				}
			}
			footer{
				a{
					display: block;
					padding: 3em 0;
				}
				i{
					font-size: 4.5rem;
					color: $white;
					opacity: .5;
					transition: transform .25s ease-in-out;
				}
				.metadata{
					position: absolute;
				}
				.icon{
					text-align: center;
				}
				clip-path: polygon(0 0, 100% 0, 100% 100%, 0 9rem);
				@include themify{
					background-color: map-get($primary, 'background-color');
				}
			}
			.card-body{
				margin-top: -4.5rem;
				padding-top: 6rem;
				background-image: linear-gradient(to bottom, $gray-200 0, transparent 7.5rem);
			}
		}
	}
}

.reeksen{
	&.scrutiny{
		.reeks{
			footer{
				.metadata{
					right: .75rem;
					top: -.25rem;
				}
			}
		}
	}
}

/*#adviezen{
	position: relative;
	&::before,
	&::after{
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		pointer-events: none;
		width: 100%;
		height: 1.5rem;
		z-index: 1;
	}
	&::before{
		top: 0;
		@include themify{
			background-image: linear-gradient(to top, transparent, rgba($gray-900, .75) 1.5rem);
		}
	}
	&::after{
		bottom: 0;
		@include themify{
			background-image: linear-gradient(to bottom, transparent, rgba($gray-900, .75) 1.5rem);
		}
	}
}*/

#adviezen{
	padding: 0;
	> div{
		padding: 3rem 0;
		//background-color: orange;
		&::before,
		&::after{
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			pointer-events: none;
			width: 100%;
			height: 1.5rem;
			z-index: 1;
		}
		&::before{
			top: 0;
			@include themify{
				background-image: linear-gradient(to top, transparent, rgba($gray-900, 1) 1.5rem);
			}
		}
		&::after{
			bottom: 0;
			@include themify{
				background-image: linear-gradient(to bottom, transparent, rgba($gray-900, 1) 1.5rem);
			}
		}
	}
}