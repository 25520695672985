.bar{
	padding: 3rem 0;
	&.content{
		margin-bottom: 3rem;
	}
	&.primary{
		@include themify{
			background-color: map-get($primary, 'background-color');
			background-image: linear-gradient(to right, map-get($primary, 'background-color'), darken(map-get($primary, 'background-color'), 10%));
			color: map-get($primary, 'color');
			.badge{
				background-color: map-get($primary, 'color');
				color: map-get($primary, 'background-color');
			}
		}
	}
	&.secondary{
		@include themify{
			background-color: map-get($secondary, 'background-color');
			background-image: linear-gradient(to right, map-get($secondary, 'background-color'), darken(map-get($secondary, 'background-color'), 10%));
			color: map-get($secondary, 'color');
			.badge{
				background-color: map-get($secondary, 'color');
				color: map-get($secondary, 'background-color');
			}
		}
	}
	&.tertiary{
		@include themify{
			background-color: map-get($tertiary, 'background-color');
			background-image: linear-gradient(to right, map-get($tertiary, 'background-color'), darken(map-get($tertiary, 'background-color'), 10%));
			color: map-get($tertiary, 'color');
			.badge{
				background-color: map-get($tertiary, 'color');
				color: map-get($tertiary, 'background-color');
			}
		}
	}
	&.accent-primary{
		@include themify{
			background-color: map-get($accent-primary, 'background-color');
			background-image: linear-gradient(to right, map-get($accent-primary, 'background-color'), darken(map-get($accent-primary, 'background-color'), 10%));
			color: map-get($accent-primary, 'color');
			.badge{
				background-color: map-get($accent-primary, 'color');
				color: map-get($accent-primary, 'background-color');
			}
		}
	}
	&.accent-secondary{
		@include themify{
			background-color: map-get($accent-secondary, 'background-color');
			background-image: linear-gradient(to right, map-get($accent-secondary, 'background-color'), darken(map-get($accent-secondary, 'background-color'), 10%));
			color: map-get($accent-secondary, 'color');
			.badge{
				background-color: map-get($accent-secondary, 'color');
				color: map-get($accent-secondary, 'background-color');
			}
		}
	}
	&.accent-tertiary{
		@include themify{
			background-color: map-get($accent-tertiary, 'background-color');
			background-image: linear-gradient(to right, map-get($accent-tertiary, 'background-color'), darken(map-get($accent-tertiary, 'background-color'), 10%));
			color: map-get($accent-tertiary, 'color');
			.badge{
				background-color: map-get($accent-tertiary, 'color');
				color: map-get($accent-tertiary, 'background-color');
			}
		}
	}
	/*&.primary,
	&.secondary,
	&.tertiary,
	&.accent-primary,
	&.accent-secondary,
	&.accent-tertiary{
		.column{
			a:link,
			a:visited,
			a:hover,
			a:active,
			a:not([href]){
				&:not(.btn){
					text-decoration: none;
					@include themify{
						color: map-get($primary, 'background-color');
					}
				}
			}
			&.content{
				@include themify{
					//background-color: map-get($primary, 'background-color');
					//background-image: linear-gradient(to right, map-get($primary, 'background-color'), darken(map-get($primary, 'background-color'), 10%));
					color: map-get($nighttime, 'background-color');
					.badge{
						background-color: map-get($nighttime, 'background-color');
						color: map-get($nighttime, 'color');
					}
				}
			}
		}
	}*/
	&.dimmed{
		background-color: $gray-100;
	}
	&.brightened{
		background-color: $white;
	}
	&.dark{
		@include themify{
			background-color: map-get($dark, 'background-color');
			background-image: linear-gradient(to right, map-get($dark, 'background-color'), darken(map-get($dark, 'background-color'), 10%));
			color: map-get($dark, 'color');
			/*.badge{
				background-color: map-get($dark, 'color');
				color: map-get($dark, 'background-color');
			}*/
		}
	}
	&.alert{
		margin-bottom: 0;
		border-radius: 0;
		border: 0 none transparent;
		&.success{
			@include themify{
				background-color: map-get($success, 'background-color');
				background-image: linear-gradient(to right, map-get($success, 'background-color'), darken(map-get($success, 'background-color'), 10%));
				color: map-get($success, 'color');
				/*.badge{
					background-color: map-get($success, 'color');
					color: map-get($success, 'background-color');
				}*/
			}
		}
		&.danger{
			@include themify{
				background-color: map-get($danger, 'background-color');
				background-image: linear-gradient(to right, map-get($danger, 'background-color'), darken(map-get($danger, 'background-color'), 10%));
				color: map-get($danger, 'color');
				/*.badge{
					background-color: map-get($danger, 'color');
					color: map-get($danger, 'background-color');
				}*/
			}
		}
		&.warning{
			@include themify{
				background-color: map-get($warning, 'background-color');
				background-image: linear-gradient(to right, map-get($warning, 'background-color'), darken(map-get($warning, 'background-color'), 10%));
				color: map-get($warning, 'color');
				/*.badge{
					background-color: map-get($warning, 'color');
					color: map-get($warning, 'background-color');
				}*/
			}
		}
		&.info{
			@include themify{
				background-color: map-get($info, 'background-color');
				background-image: linear-gradient(to right, map-get($info, 'background-color'), darken(map-get($info, 'background-color'), 10%));
				color: map-get($info, 'color');
				/*.badge{
					background-color: map-get($info, 'color');
					color: map-get($info, 'background-color');
				}*/
			}
		}
	}
	&.primary,
	&.secondary,
	&.tertiary,
	&.accent-primary,
	&.accent-secondary,
	&.accent-tertiary,
	&.dark,
	&.alert{
		a:link,
		a:visited,
		a:hover,
		a:active,
		a:not([href]){
			&:not(.btn){
				color: inherit;
			}
		}
		p,
		ol,
		ul,
		dl{
			a:link,
			a:visited,
			a:hover,
			a:active,
			a:not([href]){
				text-decoration: underline;
			}
		}
		p{
			a.btn{
				text-decoration: none;
			}
		}
	}

	&.overlay-infix,
	&.overlay-suffix{
		padding-top: 0;
		padding-bottom: 0;
		/*[class^="col"]{
			&.white{
				background-color: $white;
			}
			&.last{
				border-bottom-left-radius: $border-radius;
				border-bottom-right-radius: $border-radius;
			}
		}*/
	}
	&.overlay-suffix{
		padding-bottom: 3rem;
	}
	&.overlay-prefix{
		margin-bottom: 0 !important;
		padding-bottom: 1.5rem;
	}
	&.overlay{
		position: relative;
		z-index: 1;
		margin-bottom: 0 !important;
		padding: 0;
		.container{
			background-color: transparent;
		}
		.row{
			//[class^="col"]{
			> div{
				padding: 1.5rem 1rem;
				border-top: 3px solid $gray-400;
				border-bottom: 0 none transparent;
				border-top-left-radius: .25rem;
				border-top-right-radius: .25rem;
				box-shadow: 0 -1.5rem 2rem -.5rem rgba($gray-900, 0.125);
				color: $gray-800;
				&.white{
					background-color: $white;
				}
				&.light{
					background-image: linear-gradient(to bottom, $gray-100 0, $gray-100 3rem);
				}
				&.dark{
					@include themify{
						background-color: map-get($dark, 'background-color');
						color: map-get($dark, 'color');
					}
				}
			}
		}
		ul{
			margin-bottom: 0 !important;
		}
	}
	
	&.overlay-suffix,
	&.overlay-infix{
		z-index: 0;
		&.dimmed{
			background-color: $gray-100;
		}
		//[class^='col-']{
		> div{
			padding-left: 1.5rem;
			padding-right: 1.5rem;
			&.white{
				background-color: $white;
			}
			&.dark{
				@include themify{
					background-color: map-get($dark, 'background-color');
					color: map-get($dark, 'color');
				}
			}
			&.light{
				//background-image: linear-gradient(to bottom, $gray-100 0, $gray-100 3rem);
			}
			&.light{
				@include themify{
					background-color: map-get($light, 'background-color');
				}
			}
			&.deflated{
				padding: 0;
			}
		}
	}

	&.overlay-suffix{
		//[class^='col-']{
		> div{
			border-bottom-left-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}
		&.content{
			margin-bottom: 0;
			padding-bottom: 3rem;
		}
	}

	&.scenery{
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		&.top{
			background-position: top;
		}
		&.center{
			background-position: center;
		}
		&.bottom{
			background-position: bottom;
		}
		&.connect{
			position: relative;
			&.up{
				&:before,
				&:after{
					bottom: 100%;
				}
			}
			&.up{
				&:after{
					border-bottom-color: rgba(250, 250, 250, 1) !important;
				}
			}
			&.down{
				&.dimmed{
					&:after{
						border-top-color: transparent !important;
					}
				}
				&.text{
					&.dimmed{
						&:after{
							border-top-color: transparent !important;
						}
					}
				}
			}
		}
	}
	&.call-to-action{
		padding-top: 0;
	}
	&.content{
		margin-bottom: 3rem;
	}
	&.map{
		padding: 0;
		&.xs{
			height: 6rem;
			iframe{
				height: 6rem;
			}
		}
		&.sm{
			height: 12rem;
			iframe{
				height: 12rem;
			}
		}
		&.md{
			height: 18rem;
			iframe{
				height: 18rem;
			}
		}
		&.lg{
			height: 24rem;
			iframe{
				height: 24rem;
			}
		}
		&.xl{
			height: 30rem;
			iframe{
				height: 30rem;
			}
		}
	}
	&.xs{
		height: 6rem;
	}
	&.sm{
		height: 12rem;
	}
	&.md{
		height: 18rem;
	}
	&.lg{
		height: 24rem;
	}
	&.xl{
		height: 30rem;
	}
	h3,
	p,
	ol,
	ul,
	dl{
		&:only-child{
			//margin-bottom: 0;
		}
		&:last-of-type{
			//margin-bottom: 0;
		}
	}
	dl{
		dd:last-child{
			margin-bottom: 0;
		}
	}
	.column{
		&.title{
			display: none;
			@include media-breakpoint-up(lg){
				display: block;
			}
		}
		&.content{
			.badge{
				display: inline;
				margin-bottom: 0;
				font-family: Inconsolata, 'Courier New', Courier, monospace;
				&::after{
					content: none;
				}
				& ~ p{
					display: inline;
				}
			}
		}
	}
}

#aanbod{
	padding-top: 0;
	padding-bottom: 0;
	.nav-tabs{
		border-bottom: 0 none transparent;
		.nav-item{
			margin-right: .5rem;
		}
		.nav-link{
			padding: .75rem;
			border: 0 none transparent;
			background-color: $white;
			color: $gray-500;
			font-weight: bold;
			text-transform: uppercase;
			&.active,
			&:hover,
			&:focus{
				background-color: $white;
				color: $gray-900;
				background-image: none;
			}
		}
		.dropdown{
			display: none;
			@include media-breakpoint-up(md){
				display: block;
			}
			.dropdown-menu{
				z-index: 1050;
				margin-top: 0;
				border: 0 none transparent;
				background-color: $white;
				@include themify{
					border-bottom: 3px solid map-get($accent-primary, 'color');
				}
			}
			.dropdown-item{
				color: $gray-900;
				padding-left: .75rem;
				padding-right: .75rem;
				&.active,
				&:hover,
				&:focus{
					background-color: $white;
					color: $gray-900;
					background-image: none;
				}
				&::before{
					content: '\2014';
					padding-right: .5rem;
					font-family: Montserrat, Helvetica, Arial, sans-serif;
					font-weight: 700;
					color: $gray-500;
				}
			}
		}
	}
	.aanbod{
		margin-bottom: 3rem;
		&:last-child{
			margin-bottom: 0;
		}
		@include media-breakpoint-up(md){
			margin-bottom: 0;
		}
		.product{
			.card-header{
				.omissible{
					@include media-breakpoint-between(md, lg){
						display: none;
					}
				}
			}
			.card-img{
				min-height: 50px;
				background-image: linear-gradient(to bottom, $gray-200 0, transparent 100%) !important;
				&:hover{
					background-color: inherit;
				}
				img{
					filter: none;
				}
			}
		}
	}
}

.dakkofferhuren{
	#aanbod{
		.aanbod{
			margin-bottom: 3rem;
			&:last-child{
				margin-bottom: 0;
			}
			@include media-breakpoint-up(md){
				margin-bottom: 0;
			}
			.product{
				.card-body{
					h3{
						display: inline-block;
					}
					p{
						display: inline;
					}
				}
				.card-img{
					padding: 1.5rem;
					border-radius: $border-radius $border-radius 0 0;

					&:hover{
						background-color: inherit;
					}
					img{
						filter: none;
					}
				}
			}
		}
	}
}

#troeven,
#werkwijze,
#offerteomvang,
#openingstijden{
	.content{
		&:not(:last-child){
			margin-bottom: 3rem;
			@include media-breakpoint-up(md){
				margin-bottom: inherit;
			}
		}
		i{
			display: block;
			margin-bottom: 2rem;
			font-size: 2rem;
		}
	}
}

#openingstijden{
	h2.text-center{
		&::before{
			content: '▤';
			display: block;
			font-size: 6rem;
			font-weight: 400;
		}
	}
	dl{
		&.openingstijden{
			margin-bottom: 7.5rem;
		}
		&.sluitingsdagen{
			margin-top: 1.5rem;
		}
	}
}

#werkwijze{
	.content{
		&:last-child{
			display: none;
			@include media-breakpoint-up(lg){
				display: block;
			}
		}
		> div{
			position: relative;
			padding: 1rem;
			@include themify{
				border-top: 3px solid map-get($primary, 'background-color');
			}
			border-radius: $border-radius;
			background-color: $white;
			box-shadow: 0 0 1.5rem 0 rgba($gray-500, .5);
			background-image: linear-gradient(to bottom, $gray-200 0, transparent 100%);
			.step{
				position: absolute;
				top: 1rem;
				right: 1rem;
			}
			.icon{
				color: $gray-500;
			}
		}
	}
}

#offerteomvang{
	.content{
		.content-img{
			@include themify{
				border-top: 3px solid map-get($primary, 'background-color');
			}
			background-image: linear-gradient(to bottom, $gray-200 0, transparent 100%);
			border-top-left-radius: $border-radius;
			border-top-right-radius: $border-radius;
			img{
				max-width: 100%;
				object-fit: cover;
				opacity: .90;
			}
		}
		.content-body{
			position: relative;
			padding: 1rem;
			background-color: $white;
			border-bottom-left-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}
	}
}

#advies,
#error{
	h2{
		&::before{
			display: block;
			font-size: 6rem;
			font-weight: 400;
			line-height: 1;
		}
	}
}

#advies{
	padding-bottom: 0;
	@include media-breakpoint-up(md){
		padding-bottom: 3rem;
	}
	.email{
		margin-bottom: 1.5rem;
		font-size: 1rem;
		span{
			@include media-breakpoint-up(lg){
				display: none;
			}
		}
		@include media-breakpoint-up(lg){
			font-size: 3rem;
		}
	}
	.contact{
		display: none;
		@include media-breakpoint-up(lg){
			display: block;
		}
	}
}

#adviezen{
	h3{
		display: inline;
	}
	article{
		margin-bottom: 1.5rem;
		.collapse{
			&.show{
				display: inline !important;
				p{
					display: inline !important;
				}
			}
		}
	}
}

footer{
	div.colophon{
		.copyright{
			margin-bottom: 0;
		}
		.social{
			span{
				display: none;
			}
		}
	}
}
















#aanbod{
	.call-to-action{
		margin-top: 1.5rem;
	}
}
.call-to-action{
	//padding-top: 0;
	.btn.filter{
		margin-bottom: .5rem;
	}
}




.introduction{
	.tekst{
		&:last-of-type,
		p:last-of-type{
			margin-bottom: 0;
		}
	}
}




/*.bar{
	&.connect{
		position: relative;
		background-color: orange !important;
		&.up:before,
		&.up:after{
			bottom: 100%;
		}
		&.down:before,
		&.down:after{
			top: 100%;
		}
		&.up:after{
			@include themify{
				border-bottom-color: map-get($nighttime, 'background-color');
			}
		}
		&.down:after{
			@include themify{
				border-top-color: map-get($nighttime, 'background-color');
			}
		}
	}
	&.connect:after,
	&.connect:before{
		left: 50%;
		border: solid transparent;
		content: '';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		
	}
	&.connect:after{
		border-width: 10px;
		margin-left: -10px;
	}
	&.connect:before{
		border-width: 10px;
		margin-left: -10px;
	}
}
*/

.arrow-div {
	position: relative;
}
.arrow-div:after, .arrow-div:before {
	bottom: 100%;
	left: 50%;
	border-style: solid;
	border-color: transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow-div:after {
	border-color: rgba(136, 183, 213, 0);
	border-bottom-color: $gray-900;
	border-width: 10px;
	margin-left: -10px;
}













.bar .overlay {
    /*overflow: scroll;*/
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom: 3px solid #ced4da;
    background-color: #fff;
    color: inherit;
    box-shadow: 0 0 2rem -0.5rem rgb(33 37 41 / 13%);
}


.bar .overlay{
	@include themify{
		border-top: 3px solid map-get($primary, 'background-color');
	}
}

.bar.map {
    padding: 0;
}

.bar .overlay.full {
    padding: 0 !important;
}










.notification{
	position: relative;
	padding-top: 0;
	padding-bottom: 0;
	-webkit-box-shadow: inset 0 -10px 15px -10px rgba($black, .5);
	box-shadow: inset 0 -10px 15px -10px rgba($black, .5);
	text-align: center;

	.container-fluid{
		padding: 1rem 1.5rem;
	}

	button.close{
		position: absolute;
		top: 1rem;
		right: 1.5rem;
		z-index: 1000;
		padding: .25em .5em;
		opacity: 1;
		&:hover{
			opacity: inherit;
		}
	}

	div.icon{
		i{
			display: block;
			font-size: 2rem;
			margin-bottom: 1rem;
		}
	}

	p{
		margin: 0;
	}
}