#debug{
	display: none;
	width: 6rem;
	padding: 1.5rem;
	position: fixed;
	left: 50%;
	bottom: 3rem;
	border-radius: $border-radius;
	margin-left: -3rem;
	box-shadow: 0 0 1.5rem 0 rgba($gray-900, .5);
	text-align: center;
	@include themify{
		background-color: map-get($accent-primary, 'background-color');
		color: map-get($accent-primary, 'color');
	}

	&:after{
		content: 'Debug';
	}

	@include media-breakpoint-only(xs){
		&:after{
			content: 'XS';
		}
	}
	@include media-breakpoint-only(sm){
		&:after{
			content: 'SM';
		}
	}
	@include media-breakpoint-only(md){
		&:after{
			content: 'MD';
		}
	}
	@include media-breakpoint-only(lg){
		&:after{
			content: 'LG';
		}
	}
	@include media-breakpoint-only(xl){
		&:after{
			content: 'XL';
		}
	}
}




.overlay-suffix .form{
	/*@include themify{
		border-top: 3px solid map-get($secondary, 'background-color');
	}*/
	//border-top: 3px solid rgba($gray-900, 1);
	
	// border-top-left-radius: $border-radius;
	// border-top-right-radius: $border-radius;
	// margin-top: -1.5rem;
}

.offerteaanvraag{
	.shopping{
		.brightened.last{
			background-image: linear-gradient(to top, rgba($gray-300, 1) 0, $white 3rem);
		}
		.barcode{
			//background-color: orange;
			padding-bottom: 1.5rem;
			margin-bottom: 1rem;
		}
	}
}




.col-body{
	padding-bottom: 1.5rem;
}
.col-footer{
	margin-top: -1.5rem;
	.barcode{
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}
	border-bottom: 3px solid $gray-200;
	&.white{
		background-color: $white;
	}
	&.light{
		@include themify{
			background-color: map-get($light, 'background-color');
		}
	}
}



.bootbox{
	&.alert{
		.bootbox-body{
			div[data-tekst]:last-child{
				:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
}



.column{
	&.white{
		background-color: $white;
	}
	&.light{
		background-color: $gray-200;
	}

	&.last{
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
		border-bottom: 3px solid $gray-300;
	}
}






.pages{
	&.offerteaanvraag{
		.column{
			margin-top: -1.5rem;
			.tab-content{
				padding-bottom: 1.5rem;
			}
			.barcode{
				padding-top: 1.5rem;
				padding-bottom: 1.5rem;
			}
		}
	}
}


.call-to-actions{
	.btn{
		&.call-to-action{
			margin-bottom: .5rem;
		}
	}
	margin-bottom: -.5rem;
}


















/////////////////
/// 
/// 
/// 
/// 


// dakkofferhuren




a.stealth{
	cursor: default;
}


























  
  .table {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	padding: 2rem 0rem 0rem 0rem;
	max-width: 1100px;
  }
  
  .table-cell {
	box-sizing: border-box;
	flex-grow: 1;
	width: 100%;
	//padding: 0.8em 1.2em;
	overflow: hidden;
	list-style-type: none;
	//outline: 1px solid #ddd;
	text-align: center;
	font-weight: 300;
	margin: {
	  //top: 1px;
	  //left: 1px;
	}
	/*.content{
		margin: 5px;
		background-color: rgba($gray-300, 1);
		padding: 0.8em 1.2em;
		border-radius: $border-radius;
	}*/
	&:first-child {
		//outline: 1px solid #fff;
	  background: transparent;
		@media only screen and (max-width : 768px) {
			  display: none;
		  }
	}
	&:nth-child(3) {
	  //outline: 1px solid #30305b;
	}
	&:nth-child(-n+3) {
		padding: {
			top: 40px;
			bottom: 40px;
		}
	}
	> h3 {
	  //font-size: 26px;
		margin: {
			top: 0;
			//bottom: 3rem;
		}
	}
	&.cell-feature {
		text-align: left;
	  //font-size: 18px;
	}
	&.plattform {
	  color: limegreen;
	  a.btn {
		color: limegreen;
		&:hover {
		  background-color: limegreen;
		  border-color: limegreen;
		  color: white;
		}
	  }
	}
	&.enterprise {
	  background-color: #30305b;
	  color: #85bafc;
	  a.btn {
		color: #85bafc;
		&:hover {
		  background-color: #85bafc;
		  border-color: #85bafc;
		  color: #30305b;
		}
	  }
	}
  }
  
  /* Table columns
  ================================== */
  .table-cell  {
	width: calc(12.5%);
	&.cell-feature {
		@media only screen and (max-width : 768px) {
			width: 100%;
			text-align: center;
		}
	}
  }
  
  svg.enterprise-check path {
	fill: #30305b !important;
  }




.st-head-row{
	margin-top: 3rem;
	text-align: center;
}










.badge.daytimez{
	background-color: #dee2e6 !important;
	color: #212529;
}

.stats:nth-child(odd) {
	//background-color: #e9ecef;
}
.stats:nth-child(even) {
	//background-color: #f8f9fa;
}

.product-group{
	border-top: 3px solid orange;
	.card-img{
		.sizes{
			position: absolute;
			top: -.25rem;
			right: .75rem;
			z-index: 10;
		}
		.properties{
			display: block !important;
			bottom: .75rem;
			right: .75rem;
			top: auto;
			left: auto;
			span:last-of-type{
				margin-bottom: 0;
			}
		}
	}
	.stats{
		border: 0 none transparent;
	}

	.card-body{
		//background-image: linear-gradient(to bottom, $gray-200 0, transparent 7.5rem);
	}
	
	.details{
		position: absolute;
		/*text-align: right;*/
		bottom: 0;
		left: 0;
	}
	h3{
		text-align: right;
		margin-bottom: 0;
	}
	.badge ~ p{
		display: inline;
	}


	.card-img img{
		opacity: 1 !important;
	}
	.badge.light{
		background-color: $gray-200 !important;
		color: $dark !important;
	}
	ul.icon{
		padding-left: 1rem;
		li{
			margin-bottom: 0;
		}
	}
}


.card-body-content{
	//margin-bottom: 1.5rem;
}









.size-chart{

	.size{
		display: inline-block;

		margin-top: .75rem;
		
		.badge{
			background-color: $white;
			color: $gray-900;
		}

		
		&.active{
			&.first{
				.badge{
					//background-color: brown;
					border-top-left-radius: $border-radius;
					border-bottom-left-radius: $border-radius;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
			&.last{
				.badge{
					//background-color: orange;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					border-top-right-radius: $border-radius;
					border-bottom-right-radius: $border-radius;
				}
			}
			&.first.last{
				.badge{
					//background-color: brown;
					border-top-left-radius: $border-radius;
					border-bottom-left-radius: $border-radius;
					border-top-right-radius: $border-radius;
					border-bottom-right-radius: $border-radius;
				}
			}
			&:not(.first, .last){
				.badge{
					border-radius: 0;
				}
			}


			

			.badge{
				background-color: $gray-900;
				color: $white;
				padding: .75rem;
			}
		}
	}
}


.card-body-stats{
	ul{
		li{
			margin-bottom: 1.5rem;
			&:last-of-type{
				margin-bottom: 0;
			}
		}
	}
}





.align-center{
	text-align: center;
}















/// dakkofferhuren film


#hero{
	position: relative;
	height: 45rem;
}

#hero video{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

#hero .gradient{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, 1) 100%);
	z-index: 1000;
}

@media(min-aspect-ratio: 16/9){
	video{
		width: 100%;
		height: auto;
	}
}
@media(max-aspect-ratio: 16/9){
	video{
		width: auto;
		height: 100%;
	}
}


.dakkofferhuren{
	#aanbod .bar{
		margin-top: -9rem;
	}
}



/*.notification{
	.row{
		margin-bottom: 1rem;
		&:last-of-type{
			margin-bottom: 0;
		}
	}
}*/








.vert .carousel-item-next.carousel-item-left,
.vert .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.vert .carousel-item-next,
.vert .active.carousel-item-right {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100% 0);
}

.vert .carousel-item-prev,
.vert .active.carousel-item-left {
-webkit-transform: translate3d(0,-100%, 0);
        transform: translate3d(0,-100%, 0);
}



#aankondigingen{
	padding-top: 1rem;
	padding-bottom: 1rem;
}