.select-extend {
  display: none; }

.select-last-element .btn {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important; }

.select-extended-element {
  text-overflow: ellipsis;
  position: relative; }
  .select-extended-element.select-checkbox .active {
    background: none;
    position: relative;
    color: #212529; }
    .select-extended-element.select-checkbox .active:before {
      content: '';
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBoZWlnaHQ9IjE2IiB3aWR0aD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+ICA8cGF0aCBmaWxsPSJub25lIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0xLjg2LDYuNDUsTDUuMDUsMTAuNjQsTDEzLjUsMi4yOSI+PC9wYXRoPjwvc3ZnPg==);
      position: absolute;
      width: 16px;
      height: 16px;
      left: 4px;
      top: 8px; }
  .select-extended-element.select-checkbox .dropdown-menu .dropdown-item:active {
    color: #212529;
    background: #fff; }
  .select-extended-element.input-group-prepend {
    margin-bottom: initial; }
  .select-extended-element .btn {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; }
  .select-extended-element .dropdown-menu .select-search {
    border-radius: 0;
    border-right: 0;
    border-left: 0;
    border-top: 0; }
  .select-extended-element .dropdown-menu .select-extend-alert {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin-bottom: 0;
    display: none; }
  .select-extended-element .dropdown-menu .dropdown-item {
    overflow: hidden;
    text-overflow: ellipsis; }
