#getuigenissen-draaimolen{
	.bubble{
		position: relative;
		margin-bottom: 1.5rem;
		padding: 1rem;
		border-radius: $border-radius;
		background-color: $white;
		box-shadow: 0 0 1.5rem 0 rgba($gray-500, .5);
		&::after{
			position: absolute;
			content: '';
			height: 0;
			width: 0;
			top: 100%;
			left: 50%;
			margin-left: -5px;
			border: 0 solid transparent;
			border-top-color: $white;
			border-width: 5px;
			pointer-events: none;
		}
		p:last-of-type{
			margin-bottom: 0;
		}
	}
	/*.carousel-inner{
		overflow: visible !important;
	}*/
	footer{
		p{
			margin-bottom: 0;
		}
	}
}