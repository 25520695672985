$fa-font-path: "../fonts" !default;

@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/regular';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/brands';

body{
	font-size: 16px;
	font-family: Inconsolata, 'Courier New', Courier, monospace;
	counter-reset: section;
}

h1,
h2,
h3,
h4,
h5,
h6,
blockquote{
	font-family: Montserrat, Helvetica, Arial, sans-serif;
	font-weight: 700;
	line-height: 1.25;
	text-transform: uppercase;
	text-rendering: optimizeLegibility;
	a:link,
	a:visited,
	a:hover,
	a:active{
		color: inherit !important;
		text-decoration: none;
	}
}

h1{
	margin-top: 3rem;
	margin-bottom: 4.5rem;
	font-size: 3rem;
	span{
		display: inline;
	}
	span:first-of-type{
		@include media-breakpoint-up(xl){
			display: block;
		}
		&::before{
			display: block;
			margin-left: -0.5rem;
			font-size: 6rem;
			font-weight: 400;
			line-height: 1;
		}
	}
	span:last-of-type{
		@include themify{
			background: linear-gradient(180deg, $white 50%, map-get($tertiary, 'background-color') 50%);
		}
	}
}
.home{
	h1{
		span:last-of-type{
			@include themify{
				background: linear-gradient(180deg, $gray-100 50%, map-get($tertiary, 'background-color') 50%);
			}
		}
	}
}
h2,
h3,
h4,
h5,
h6{
	margin-bottom: 1.5rem;
	font-size: 1rem;
	&::after{
		content: '\2014';
		display: block;
		color: $gray-500;
	}
	&.popover-header,
	&.modal-title{
		margin-bottom: inherit;
		&::after{
			content: none;
			color: inherit;
		}
	}
	&.stealth,
	&.badge{
		margin-bottom: inherit;
		font-family: Inconsolata, 'Courier New', Courier, monospace;
		font-weight: inherit;
		text-transform: inherit;
		text-rendering: auto;
		&::after{
			content: none;
			color: inherit;
		}
	}
}
h2{
	b{
		display: block;
	}
}
h3{
	margin-bottom: 1.5rem;
}

p,
ol,
ul,
dl{
	hyphens: auto;
	&:not(:last-child){
		margin-bottom: 1.5rem;
	}
}

ul{
	list-style-type: square;
	&.row{
		padding-left: 0;
		padding-right: 0;
		list-style: none;
	}
	&.none{
		margin-left: 0;
		padding-left: 0;
		list-style: none;
	}
	&.inline{
		padding-left: 0;
		list-style: none;
		li{
			display: inline;
		}
	}
	&.dashed{
		padding-left: 1rem;
		list-style: none;
		& > li::before{
			content: '\2014\00a0';
			display: inline-block;
			width: 1rem;
			margin-left: -1em;
		}
	}
	&.stepped{
		margin-left: 0;
		padding-left: 0;
		list-style: none;
		li{
			.step{
				float: left;
				margin-right: .5rem;
			}
		}
	}
	&.piped{
		padding-left: 0;
		list-style: none;
		li{
			display: inline;
		}
		& > li::after{
			content: '\007C';
		}
		& > li:last-of-type::after{
			content: none;
		}
	}
	&.comma-separated{
		padding-left: 0;
		list-style: none;
		li{
			display: inline-block;
			&::after{
				content: '\002c\00a0';
			}
			&:last-child{
				&::after{
					content: none;
				}
			}
			&:nth-last-child(2){
				&::after{
					content: '\00a0\0065\006E\00a0';
				}
			}
		}
		&.full-stop{
			li{
				&:last-child{
					&::after{
						content: '\002e';
					}
				}
			}
		}
		&.endless{
			li{
				&:nth-last-child(2){
					&::after{
						content: '\002c\00a0';
					}
				}
				&:last-child{
					&::after{
						content: '\002c\00a0\2026';
					}
				}
			}
		}
	}
	&.abate{
		li{
			&.last{
				&::after{
					content: '\002c\00a0\2026\00a0';
				}
			}
		}
	}
	/*&.alternatives{
		li{
			white-space: nowrap;
		}
	}*/
	&.icon{
		padding-left: 2.5rem;
		list-style-type: none;
		li{
			position: relative;
			i::before{
				font-family: 'Font Awesome 5 Free';
				font-style: normal;
				content: '\f30b';
			}
			&.pro{
				i::before{
					content: '\f058';
					@include themify{
						color: map-get($success, 'background-color');
					}
				}
			}
			&.con{
				i::before{
					content: '\f057';
					@include themify{
						color: map-get($danger, 'background-color');
					}
				}
			}
		}
		.fa-li{
			left: -1.5rem;
			position: absolute;
			text-align: center;
			width: 1.5rem;
			line-height: inherit;
		}
	}
	&.btn-actions{
		margin-left: 0;
		padding-left: 0;
		list-style: none;
		li{
			margin-bottom: .5rem;
			&:last-of-type{
				margin-bottom: .5rem;
			}
		}
	}
}

dl{
	hyphens: none;
	dd{
		margin-bottom: 1.5rem;
	}
	&.definitions-only{
		dt{
			display: none;
		}
		dd{
			margin-bottom: 0;
		}
	}
	&.compacted{
		overflow: hidden;
		dt{
			width: 1.5rem;
			float: left;
			clear: left;
		}
		dd{
			float: left;
			clear: right;
			margin-bottom: 0;
		}
	}
	&.centered{
		margin: 0 auto;
		dt,
		dd{
			text-align: center;
		}
		/*&::after{
			display: block;
			font-family: Montserrat, Helvetica, Arial, sans-serif;
			font-weight: 700;
			color: $gray-500;
			content: '\2014';
		}*/
	}
	&.piped{
		dt,
		dd{
			display: inline;
		}
		dd{
			&::after{
				content: '\007C';
			}
			&:last-of-type::after{
				content: none;
			}
		}
	}
	&.tags,
	&.rubrieken{
		dt{
			float: left;
			clear: left;
		}
		dd{
			margin-bottom: 1.5rem;
		}
		.tag,
		.rubriek{
			margin-right: .5rem;
			float: left;
		}
	}
	&.dashed{
		dt{
			float: left;
			clear: left;
			&::after{
				margin-right: .5rem;
				margin-left: .5rem;
				font-weight: 700;
				color: $gray-500;
				content: '\2014\2014\2014';
			}
		}
		dd,
		p,
		ul{
			margin-bottom: 1.5rem;
		}	
	}
	&.inline{
		dt{
			float: left;
			clear: left;
			margin-right: .5rem;
		}
		dd,
		p,
		ul{
			margin-bottom: 1.5rem;
		}	
	}
	&.contact{
		overflow: hidden;
		dt{
			width: 1.5rem;
			float: left;
			clear: left;
		}
		dd{
			float: left;
			clear: right;
		}
	}
	&.openingstijden{
		text-align: center;
		dt,
		dd{
			display: inline;
			white-space: nowrap;
		}
		dd{
			&::after{
				content: ', ';
			}
			&:last-of-type{
				&::after{
					content: none;
				}
			}
			&:nth-last-of-type(2){
				&::after{
					content: '\00A0\0065\006E';
				}
			}
		}
	}
}

blockquote{
	width: 21rem;
	max-width: 100%;
	margin-top: 3rem;
	margin-bottom: 3rem;
	line-height: 1.5;
	/*&::before{
		content: '\201C';
		display: block;
		z-index: 1;
		//margin-left: -1.5rem;
		font-size: 3rem;
		font-weight: 700;
		font-family: Montserrat, Helvetica, Arial, sans-serif;
		color: $gray-200;
	}*/
	&::before,
	&::after{
		content: '\2014';
		display: block;
		font-family: Montserrat, Helvetica, Arial, sans-serif;
		font-weight: 700;
		color: $gray-500;
	}
	span{
		@include themify{
			background: linear-gradient(180deg, $white 50%, rgba(map-get($tertiary, 'background-color'), .5) 50%);
		}
	}
}

a:link,
a:visited,
a:hover,
a:active,
a:not([href]){
	cursor: pointer;
	text-decoration: none;
	@include themify{
		color: map-get($accent-primary, 'background-color');
	}
	&.stealth{
		color: inherit !important;
		text-decoration: none;
	}
}

.toast{
	a:link,
	a:visited,
	a:hover,
	a:active,
	a:not([href]){
		&:not(.btn){
			color: inherit;
		}
	}
	p{
		a:link,
		a:visited,
		a:hover,
		a:active,
		a:not([href]){
			text-decoration: underline;
		}
	}
}


small{
	font-size: inherit;
}

.fa,
.fas,
.fab,
.far{
	font-size: .75rem;
}

footer{
	p.copyright,
	p.error,
	ul.legal{
		a:link,
		a:visited,
		a:hover,
		a:active{
			color: $gray-600;
		}
	}
}

/*.hyphenate{
	hyphens: auto;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
}*/

.libretto{
	h4,
	h5:not(.modal-title),
	h6{
		font-family: Inconsolata, 'Courier New', Courier, monospace;
		@extend .badge;
		display: table;
		&::after{
			content: none;
		}
	}
	h5:not(.modal-title){
		@extend .badge;
	}
}

.libretto{
	h4{
		counter-reset: subsection;
	}
	h4::before{
		counter-increment: section;
		//content: counter(section) '. ';
		content: counter(section) '\00A0\2015\00A0';
	}
	
	h5:not([class])::before{
		counter-increment: subsection;
		content: counter(section) '.' counter(subsection) '\00A0\2015\00A0';
	}
}

.omissible{
	display: none;
	@include media-breakpoint-only(xs){
		display: inline;
	}
	@include media-breakpoint-up(xl){
		display: inline;
	}
}

.truncate,
.abate{
	.hide{
		display: none;
	}
}

.truncate{
	.show{
		&.last{
			margin-bottom: 0;
		}
	}
}

.introduction{
	/*header{
		p{
			&:last-of-type{
				background-color: red;
				margin-bottom: 0;
			}
		}
	}*/
	h3{
		font-family: inherit;
		font-weight: normal;
		text-transform: none;
		line-height: 1.5;
		&::after{
			content: none;
		}
	}
}

.tekst,
.film{
	margin-bottom: 1.5rem;
}

abbr[title],
abbr[data-original-title]{
	text-decoration: none;
	cursor: inherit;
}

.true{
	@include themify{
		color: map-get($success, 'background-color');
	}
}
.false{
	@include themify{
		color: map-get($danger, 'background-color');
	}
}